import React, { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDispatchUserStatus } from '../../app/AppDispatcher';
import { AppHeader } from '../app_header/AppHeader';

export function UserViewBase({ children }: PropsWithChildren) {
  // console.log(`Login userStatus: ${userStatus}`);
  useDispatchUserStatus();

  return (
    <div className="App">
      <AppHeader />
      {children}
    </div>
  );
}
