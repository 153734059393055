import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';

import { isObject } from '../../appUtils';
import { selectUser, signTerms } from '../../state/userSlice';
import { AppHeader } from '../app_header/AppHeader';
import style from './Login.module.css';
import styles from './Login.module.css';
import { UserViewBase } from './UserViewBase';

dayjs.extend(utc);

export function TermsContent() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState(user.email);
  const [org, setOrg] = useState('');
  const [orgSecondary, setOrgSecondary] = useState('');
  const [hasSecondary, setHasSecondary] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmTime, setConfirmTime] = useState(undefined);
  const [errors, setErrors] = useState({} as any);

  useEffect(() => {
    setEmail(user.email);
  }, [user]);

  function canSubmit() {
    const can =
      confirm &&
      name.length > 0 &&
      title.length > 0 &&
      email.length > 0 &&
      org.length > 0 &&
      (!hasSecondary || orgSecondary !== '');
    return can;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (canSubmit()) {
      try {
        const originalPromiseResult = await dispatch(
          // @ts-ignore
          // ! TS2345: Argument of type 'AsyncThunkAction<any, { username: string; password: string; }, AsyncThunkConfig>' is not assignable to parameter of type 'AnyAction'.
          signTerms({
            name,
            title,
            email,
            org,
            org_secondary: orgSecondary,
            confirmed: confirm,
            confirmed_date: confirmTime,
          }),
        ).unwrap();
        console.log(
          `Terms sign ${email} fullfilled: ${JSON.stringify(
            originalPromiseResult,
          )}`,
        );
      } catch (rejectedValueOrSerializedError) {
        console.log(
          `Terms sign ${email} failed with error: ${JSON.stringify(
            rejectedValueOrSerializedError,
          )}`,
        );
        if (isObject(rejectedValueOrSerializedError.error)) {
          setErrors(rejectedValueOrSerializedError.error);
        } else {
          setErrors({ non_field_errors: rejectedValueOrSerializedError.error });
        }
      }
    }
  };

  return (
    <div className={style.terms_content_container}>
      <div className={style.terms_content}>
        {errors?.non_field_errors && (
          <div className={styles.login_error_message}>
            {errors.non_field_errors}
          </div>
        )}
        <Box>
          <TextField
            className={style.terms_input}
            size="small"
            variant="standard"
            autoComplete="name"
            label="Your Name"
            value={name}
            helperText={errors?.name}
            error={Boolean(errors?.name)}
            required
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            className={style.terms_input}
            size="small"
            variant="standard"
            autoComplete="organization-title"
            label="Your Job Title"
            value={title}
            helperText={errors?.title}
            error={Boolean(errors?.title)}
            required
            onChange={(event) => setTitle(event.target.value)}
          />
          <TextField
            className={style.terms_input}
            size="small"
            variant="standard"
            autoComplete="email"
            label="Your Email Address"
            value={email}
            helperText={errors?.email}
            error={Boolean(errors?.email)}
            required
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            className={style.terms_input}
            size="small"
            variant="standard"
            autoComplete="organization"
            label="Your Organization Name"
            value={org}
            helperText={errors?.org}
            error={Boolean(errors?.org)}
            required
            onChange={(event) => setOrg(event.target.value)}
          />
        </Box>
        <Box className={style.terms_second_org_container}>
          <FormGroup className={style.terms_second_org}>
            <FormControlLabel
              control={
                <Checkbox
                  value={hasSecondary}
                  onChange={(event) => setHasSecondary(event.target.checked)}
                />
              }
              label="Are you requesting access to StreetLight Construction & Event Solutions through an Account belonging to a different Organization?"
            />
          </FormGroup>
          {hasSecondary && (
            <TextField
              className={style.terms_input}
              size="small"
              variant="standard"
              label="Name of Organization"
              value={orgSecondary}
              helperText={errors?.org_secondary}
              error={Boolean(errors?.org_secondary)}
              onChange={(event) => setOrgSecondary(event.target.value)}
            />
          )}
        </Box>
        <Box className={style.terms_content_text}>
          <p>
            <b>
              You are being provided access to the StreetLight Construction &
              Event Solutions Web App. As a user, you have an important
              responsibility to ensure the responsible use of the Data Products
              and Subscribed Output. Please read and acknowledge receipt of the
              following User Acknowledgment by clicking the CONFIRM button
              below:
            </b>
          </p>
          <p>
            Your access is provided via an End User License Agreement (the
            “License Agreement”) between StreetLight Data, Inc. and Your
            Organization. You are responsible for knowing and abiding by the
            terms and restrictions set forth in the License Agreement.
          </p>
          <p>
            With respect to the use of the Subscribed Output (the materials
            generated by and through access to and/or use of the StreetLight
            Data Products), you hereby confirm: (i) you do not have the ability
            to use the StreetLight Construction & Event Solutions Web App and/or
            the Subscribed Output to determine the identity of any specific
            person; (ii) you shall make no attempt to obtain data permitting you
            to use the StreetLight Construction & Event Solutions Web App and/or
            the Subscribed Output to determine the identity of any person; (iii)
            the you will not accept any information from any third party that
            permits the use of the Subscribed Output to make such an
            identification; and (iv) you will not use the StreetLight
            Construction & Event Solutions Web App and/or the Subscribed Output
            to determine the identity of any specific person.
          </p>
        </Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={confirm}
                onChange={(event) => {
                  setConfirm(event.target.checked);
                  const now = dayjs().utc().format('YYYY-MM-DDTHH:mm:ssZ');
                  setConfirmTime(now);
                }}
              />
            }
            label="I ACKNOWLEDGE I HAVE RECEIVED AND READ THIS USER ACKNOWLEDGMENT."
          />
        </FormGroup>
        <Button
          className={style.terms_input}
          variant="outlined"
          disabled={!canSubmit()}
          onClick={(e) => handleSubmit(e)}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

export function Terms() {
  return (
    <UserViewBase>
      <div className="App-body">
        <TermsContent />
      </div>
    </UserViewBase>
  );
}
