import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Add, Check, Close, Delete, Edit, Search } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import TextField from '@mui/material/TextField';

import {
  SavedRoute,
  SavedRouteList,
  selectInSavedRouteEditMode,
  setDisplayedSavedRoute,
  setInSavedRouteEditMode,
  useGetSavedRoutesQuery,
  useSavedRouteCreateMutation,
  useSavedRouteDeleteMutation,
  useSavedRouteUpdateMutation,
} from 'state/savedRouteSlices';
import { useGetLayerQuery } from 'state/apiSlice';
import { LayerContext } from 'state/LayerContext';
import { updateNameError } from './util';

import {
  selectProject,
  selectRouteSelection,
  selectRouteSelectionOrSegmentIdRoute,
  setMapCenter,
  setRouteSelection,
  setSelectedSegmentId,
} from '../../state/workflowSlice';
import styles from './SavedRoutesToolbar.module.css';
import { jsonTupeToLatlng } from '../map/mapUtils';
import {
  routeSelectionIsSavedRoute,
  useRouteInfo,
} from '../common/useRouteInfo';

export function SavedRoutesToolbar() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [editSavedRouteName, setEditSavedRouteName] = useState('');
  const [editSavedRouteId, setEditSavedRouteId] = useState(undefined);
  const [deleteSavedRouteId, setDeleteSavedRouteId] = useState(undefined);
  const [savedRoutes, setSavedRoutes] = useState<SavedRouteList>([]);
  const [pendingPanToRoute, setPendingPanToRoute] = useState<boolean>(false);
  const project_slug = useSelector(selectProject);
  const inSavedRouteEditMode = useSelector(selectInSavedRouteEditMode);
  const selectedRoute = useSelector(selectRouteSelectionOrSegmentIdRoute);
  const routeSelection = useSelector(selectRouteSelection);
  const { layer } = useContext(LayerContext);

  const { currentData: savedRouteData } = useGetSavedRoutesQuery(project_slug, {
    skip: !project_slug,
  });

  const { savedRoute: savedRouteInfo, basicRouteInfo } = useRouteInfo();

  const [updateSavedRoute, { data: updateSavedRouteData }] =
    useSavedRouteUpdateMutation();
  const [createSavedRoute, { data: createSavedRouteData }] =
    useSavedRouteCreateMutation();
  const [deleteSavedRoute, deleteSavedRouteStatus] =
    useSavedRouteDeleteMutation();

  const { currentData: layerData } = useGetLayerQuery(layer, { skip: !layer });

  useEffect(() => {
    let routesToDisplay = savedRouteData?.savedRoutes || [];

    if (search && showSearch) {
      routesToDisplay = routesToDisplay.filter((value) =>
        value.routeName.toLowerCase().includes(search.toLowerCase()),
      );
    }

    setSavedRoutes(routesToDisplay);
  }, [savedRouteData, search, showSearch]);

  useEffect(() => {
    if (savedRouteInfo?.centerPoint) {
      if (pendingPanToRoute) {
        dispatch(
          setMapCenter(
            jsonTupeToLatlng(savedRouteInfo.centerPoint.coordinates as any),
          ),
        );
        setPendingPanToRoute(false);
      }
    }
  }, [savedRouteInfo]);

  const onEditClick = (event, index) => {
    const savedRoute = savedRoutes[index];
    dispatch(setRouteSelection(savedRoute.id));
    dispatch(
      setSelectedSegmentId(
        savedRoute.routeSegmentIds[savedRoute.routeSegmentIds.length - 1],
      ),
    );
    dispatch(setInSavedRouteEditMode(true));
    setEditSavedRouteName(savedRoute.routeName);
    setEditSavedRouteId(savedRoute.id);
    setPendingPanToRoute(true);
  };

  function exitEditMode() {
    dispatch(setInSavedRouteEditMode(false));
    setEditSavedRouteId(undefined);
    setEditSavedRouteName('');
  }

  const onEditSubmit = (event, index) => {
    const savedRoute = savedRoutes[index];
    console.assert(savedRoute.id === editSavedRouteId);
    const { waypointSegments, realizedSegments } = basicRouteInfo;
    updateSavedRoute({
      project_slug: project_slug as string,
      savedRouteId: editSavedRouteId,
      savedRoute: {
        routeName: editSavedRouteName,
        routeSegmentIds: waypointSegments,
        realizedSegments,
      },
    });
    exitEditMode();
  };

  useEffect(() => {
    const new_id = updateSavedRouteData?.id;
    if (new_id && new_id !== routeSelection) {
      dispatch(setRouteSelection(new_id));
    }
  }, [updateSavedRouteData]);

  const onAddClick = (event) => {
    dispatch(setDisplayedSavedRoute(undefined));
    dispatch(setInSavedRouteEditMode(true));
    setEditSavedRouteName('');
    setEditSavedRouteId(-1);
  };

  const onAddSubmit = (event) => {
    dispatch(setInSavedRouteEditMode(false));
    if (editSavedRouteName) {
      const { waypointSegments, realizedSegments } = basicRouteInfo;
      createSavedRoute({
        project_slug: project_slug as string,
        savedRoute: {
          routeName: editSavedRouteName,
          routeSegmentIds: waypointSegments,
          realizedSegments,
        },
      });
    }
    exitEditMode();
  };

  useEffect(() => {
    const new_id = createSavedRouteData?.id;
    if (new_id && new_id !== routeSelection) {
      dispatch(setRouteSelection(new_id));
    }
  }, [createSavedRouteData]);

  const onSelect = (event, index) => {
    exitEditMode();
    const savedRoute = savedRoutes[index];
    const route = savedRoute.routeSegmentIds;
    dispatch(setRouteSelection(savedRoute.id));
    setPendingPanToRoute(true);
  };

  const onDeleteClick = (id: string) => {
    setDeleteSavedRouteId(id);
    exitEditMode();
  };

  const onDeleteSubmit = (event, index) => {
    const savedRoute = savedRoutes[index];
    console.assert(
      savedRoute.id === deleteSavedRouteId,
      deleteSavedRouteId,
      savedRoute.id,
      savedRoute,
    );
    deleteSavedRoute({
      project_slug: project_slug as string,
      savedRouteId: deleteSavedRouteId,
    });
    setDeleteSavedRouteId(undefined);
    if (routeSelection === deleteSavedRouteId) {
      dispatch(setRouteSelection(undefined));
      dispatch(setSelectedSegmentId(undefined));
    }
  };

  useEffect(() => {
    if (savedRouteInfo) {
      const route = savedRouteInfo.routeSegmentIds;
      dispatch(setSelectedSegmentId(route[route.length - 1]));
    }
  }, [savedRouteInfo]);

  function routeUnchangedError() {
    if (routeSelectionIsSavedRoute(routeSelection)) {
      return 'Please change the route selection before saving';
    }
    return undefined;
  }

  function renderItem(savedRoute: SavedRoute, index) {
    const { routeName, id } = savedRoute;
    const isEditing = editSavedRouteId === id && inSavedRouteEditMode;
    const isDeleting = deleteSavedRouteId === id;

    let secondaryActions;
    let itemContent;

    if (!isEditing && !isDeleting) {
      // Normal savedRoute view
      secondaryActions = (
        <>
          <IconButton
            className={styles.bookmark_edit_button}
            edge="end"
            aria-label="edit"
            title="Edit"
            onClick={(event) => onEditClick(event, index)}
          >
            <Edit />
          </IconButton>
          <IconButton
            title="Delete"
            className={styles.bookmark_delete_button}
            onClick={(event) => onDeleteClick(id)}
          >
            <Delete />
          </IconButton>
        </>
      );
      itemContent = (
        <ListItemButton
          className={styles.bookmark_item_button}
          onClick={(event) => onSelect(event, index)}
        >
          <ListItemText primary={routeName} />
        </ListItemButton>
      );
    } else if (isDeleting) {
      secondaryActions = (
        <>
          <IconButton
            edge="end"
            aria-label="delete"
            title="Delete"
            color="error"
            onClick={(event) => onDeleteSubmit(event, index)}
          >
            <Check />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="cancel"
            title="Cancel"
            color="success"
            onClick={(event) => setDeleteSavedRouteId(undefined)}
          >
            <Close />
          </IconButton>
        </>
      );
      itemContent = (
        <ListItemText
          className={styles.confirm_delete_field}
          primary={`Delete '${routeName}'?`}
        />
      );
    } else {
      const editErrorString = updateNameError(
        routeName,
        editSavedRouteName,
        savedRoutes.map((e) => e.routeName),
      );
      // Editing view
      secondaryActions = (
        <>
          <IconButton
            edge="end"
            aria-label="submit edit"
            title="Submit edit"
            onClick={(event) => onEditSubmit(event, index)}
            disabled={!!editErrorString}
          >
            <Check />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="cancel"
            title="Cancel"
            onClick={(event) => exitEditMode()}
          >
            <Close />
          </IconButton>
        </>
      );
      itemContent = (
        <TextField
          label="Name"
          value={editSavedRouteName}
          onChange={(event) => setEditSavedRouteName(event.target.value)}
          variant="standard"
          size="small"
          className={styles.edit_name_field}
          error={!!editErrorString}
          helperText={editErrorString}
        />
      );
    }
    const selected =
      (id === editSavedRouteId && isEditing) || id === routeSelection;
    return (
      <ListItem
        disablePadding
        secondaryAction={secondaryActions}
        selected={selected}
        className={
          selected ? styles.saved_route_item_selected : styles.saved_route_item
        }
        key={id}
      >
        {itemContent}
      </ListItem>
    );
  }
  const isAdding = inSavedRouteEditMode && editSavedRouteId === -1;
  return (
    <>
      <div className={styles.header_padded}>
        {!showSearch ? (
          <>
            <IconButton
              onClick={onAddClick}
              size="small"
              title="Create a route"
              disabled={!selectedRoute}
              className={styles.no_left_padding}
            >
              <Add />
            </IconButton>
            <IconButton
              onClick={(event) => setShowSearch(true)}
              size="small"
              title="Search routes"
            >
              <Search />
            </IconButton>
          </>
        ) : (
          <>
            <TextField
              label="Route name"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              variant="standard"
              size="small"
              className={styles.search_text_field}
            />
            <IconButton
              onClick={(event) => setShowSearch(false)}
              size="small"
              title="Hide search"
            >
              <Close />
            </IconButton>
          </>
        )}
      </div>
      {isAdding && (
        <div className={styles.header_padded}>
          <TextField
            label="New route name"
            value={editSavedRouteName}
            onChange={(event) => setEditSavedRouteName(event.target.value)}
            variant="standard"
            size="small"
            className={styles.search_text_field}
            error={
              !!updateNameError(
                undefined,
                editSavedRouteName,
                savedRoutes.map((e) => e.routeName),
              )
            }
            helperText={updateNameError(
              undefined,
              editSavedRouteName,
              savedRoutes.map((e) => e.routeName),
            )}
          />
          <Tooltip title={routeUnchangedError()}>
            <span style={{ display: 'flex' }}>
              <IconButton
                onClick={onAddSubmit}
                disabled={
                  !editSavedRouteName ||
                  !!updateNameError(
                    undefined,
                    editSavedRouteName,
                    savedRoutes.map((e) => e.routeName),
                  ) ||
                  !!routeUnchangedError()
                }
                size="small"
              >
                <Check />
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            edge="end"
            aria-label="cancel"
            title="Cancel"
            onClick={(event) => {
              dispatch(setInSavedRouteEditMode(false));
            }}
          >
            <Close />
          </IconButton>
        </div>
      )}
      {savedRoutes && savedRoutes.length > 0 ? (
        <List className={styles.bookmark_toolbar_list}>
          {savedRoutes.map(renderItem)}
        </List>
      ) : (
        <div>
          <p className={styles.no_bookmarks_message}>No saved routes found</p>
          <p className={styles.no_bookmarks_message}>
            Select a route on the map and press + to add
          </p>
        </div>
      )}
    </>
  );
}
