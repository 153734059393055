import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppHeader } from 'features/app_header/AppHeader';
import './App.css';

import {
  checkUserStatus,
  kUserStatusLoggedIn,
  kUserStatusLoggedOut,
  kUserStatusPendingTerms,
  kUserStatusUnknown,
  selectUserStatus,
} from 'state/userSlice';

export function useDispatchUserStatus() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStatus = useSelector(selectUserStatus);
  const { pathname } = useLocation();

  function maybeNavigate(target_pathname) {
    if (pathname !== target_pathname) {
      navigate({ pathname: target_pathname });
    }
  }

  useEffect(() => {
    if (userStatus === kUserStatusUnknown) {
      // if user status is unknown
      // ! TS2345: Argument of type 'AsyncThunkAction<any, void, AsyncThunkConfig>' is not assignable to parameter of type 'AnyAction'.
      // @ts-ignore
      dispatch(checkUserStatus());
    } else if (userStatus === kUserStatusLoggedIn) {
      // if user is logged in
      const searchParams = new URLSearchParams(window.location.search);
      if (!window.location.toString().includes('/app')) {
        navigate({
          pathname: '/app',
          search: searchParams.toString(),
        });
      }
    } else if (userStatus === kUserStatusLoggedOut) {
      // if user is logged out
      maybeNavigate('/login');
    } else if (userStatus === kUserStatusPendingTerms) {
      maybeNavigate('/terms');
    }
  }, [userStatus]);
}

export function AppDispatcher() {
  const userStatus = useSelector(selectUserStatus);
  // const searchParams = new URLSearchParams(window.location.search).toString();
  console.log(`AppDispatcher - userStatus: ${userStatus}`);
  useDispatchUserStatus();

  if (
    ![kUserStatusUnknown, kUserStatusLoggedIn, kUserStatusLoggedOut].includes(
      userStatus,
    )
  ) {
    return (
      <div className="App">
        <AppHeader />
        <div className="App-body">
          <div>
            userStatus:
            {userStatus}
            ...
          </div>
        </div>
      </div>
    );
  }
}
