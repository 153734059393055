import React from 'react';

import { SpeedChart } from 'features/chart/SpeedChart';

import { WorkflowRecent } from 'features/workflow_nrt/WorkflowRecent';
import { ExportSpeedsButton } from 'features/workflow_nrt/ExportSpeedsButton';
import { WorkflowType } from '../../appConstants';
import {
  getSpecificWorkflowState,
  getWorkflowState,
} from '../../state/workflowSlice';
import { ReliesOn } from '../workflow_common/NoDataLoadedWarning';
import { WorkflowInfo } from '../workflow_common/workflowLookup';

export const workflowSpeed: WorkflowInfo = {
  dataAvailableReliesOn: [ReliesOn.nrtDay, ReliesOn.typicalMonth],
  switchToState: (fromWorkflow, state) => {
    // console.log('Handling switch from ', fromWorkflow, 'to', state.current, state);
    if ([WorkflowType.Plan, WorkflowType.Speed].includes(fromWorkflow)) {
      getWorkflowState(state).segmentId = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).segmentId;
    }
    if (
      [
        WorkflowType.Timeline,
        WorkflowType.Speed,
        WorkflowType.TravelTime,
      ].includes(fromWorkflow)
    ) {
      getWorkflowState(state).targetDate = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).targetDate;
      getWorkflowState(state).comparison = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).comparison;
    }
  },
};

export function WorkflowSpeed() {
  return (
    <WorkflowRecent
      chart={<SpeedChart />}
      graph_title="Speeds"
      chartSettingsExtras={<ExportSpeedsButton />}
    />
  );
}
