import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactHeap from 'reactjs-heap';

import { selectUser } from 'state/userSlice';

import { selectProject } from '../../state/workflowSlice';

class HeapAnalyticsService {
  userProperties: { project_slug?: string; domain?: string } = {};
  userId?: string = undefined;

  get heap() {
    // @ts-ignore Property 'heap' does not exist on type 'Window & typeof globalThis'.
    return window.heap;
  }

  initialize() {
    if (this.isInitialized) return;
    ReactHeap.initialize(2183439397);
    this.userProperties.domain = `${window.location.hostname}:${window.location.port}`;
    this.addUserProperties();
  }

  get isInitialized() {
    return !!this.heap?.appid;
  }

  setUserIdentify(userId: string) {
    if (!this.isInitialized) return;
    if (userId !== this.userId && userId) {
      // console.log('Identify ', userId);
      this.heap.identify(
        `${userId}/${window.location.hostname}:${window.location.port}`,
      );
    }
  }

  resetUserIdentify() {
    if (!this.isInitialized) return;

    this.heap.resetIdentity();
    this.heap.clearEventProperties();
  }

  addUserProperties() {
    if (!this.isInitialized) return;
    // console.log('Writing properties to heap ', this.userProperties);
    this.heap.addUserProperties(this.userProperties);
  }

  setProjectProperty(project_slug: string) {
    this.userProperties.project_slug = project_slug;
    this.addUserProperties();
  }
}

export const heapAnalyticsService = new HeapAnalyticsService();

export function HeapAnalytics() {
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  heapAnalyticsService.initialize();

  useEffect(() => {
    heapAnalyticsService.setUserIdentify(user.email);
    heapAnalyticsService.setProjectProperty(project);
  }, [user, project]);

  return null;
}
