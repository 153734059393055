import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from 'state/userSlice';

import { Logout } from 'features/login/Logout';

import styles from './AppHeader.module.css';
import { ProjectSwitcher } from './ProjectSwitcher';
import stl_ops_logo from './StreetLight_Operations.png';
import stl_con_logo from './StreetLight_Construction.png';
import { ProductSwitcher } from './ProductSwitcher';
import { WalkmeHelpButton } from '../login/WalkMe';

export function AppHeader({ children }: { children?: React.ReactNode }) {
  let logo = stl_ops_logo;
  if (process.env.REACT_APP_PRODUCT_NAME === 'StreetLight Construction') {
    logo = stl_con_logo;
  }

  return (
    <header className={styles.AppHeader}>
      <img alt="streetlight_logo" src={logo} />
      {children}
    </header>
  );
}

// // https://blog.logrocket.com/validate-react-props-proptypes

export function ProjectAppHeader() {
  const user = useSelector(selectUser);

  return (
    <AppHeader>
      <div className={styles.app_info}>
        <span className={styles.project_name}>
          <ProjectSwitcher />
        </span>
        <div className={styles.spacer} />
        <div className={styles.user_info}>
          <WalkmeHelpButton />
          <ProductSwitcher />
          <Logout>{user}</Logout>
        </div>
      </div>
    </AppHeader>
  );
}
