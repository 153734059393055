import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  selectBinSize,
  selectComparison,
  selectProject,
  selectRouteSelection,
  selectSegmentId,
  selectTargetDate,
  selectTypicalMonth,
} from '../../state/workflowSlice';
import { LayerContext } from '../../state/LayerContext';
import {
  useGetLayerQuery,
  useGetSegmentSpeedsQuery,
  useGetTypicalSpeedsQuery,
} from '../../state/apiSlice';
import { getRouteForSparseSegments } from '../map/mapUtils';
import { isComparisonMonth } from '../task_bar/ComparisonPicker';

export function useSpeedsDataProvider() {
  const project_slug = useSelector(selectProject);
  const targetDate: string = useSelector(selectTargetDate);
  const segmentId = useSelector(selectSegmentId);
  const routeSelection = useSelector(selectRouteSelection);
  const comparison = useSelector(selectComparison);

  // speedData from REST api
  const { currentData: speedData } = useGetSegmentSpeedsQuery(
    { project_slug, date: targetDate, segmentId },
    { skip: !(targetDate && segmentId) },
  ) as any;

  const isMonthComparison = comparison && isComparisonMonth(comparison);
  // typicalData from REST api
  const { currentData: typicalSpeedData } = useGetTypicalSpeedsQuery(
    { project_slug, typicalMonth: comparison, segmentId, date: targetDate },
    { skip: !(comparison && segmentId && isMonthComparison) },
  ) as any;

  const { currentData: comparisonDaySpeedData } = useGetSegmentSpeedsQuery(
    { project_slug, date: comparison, segmentId, comparedTo: targetDate },
    { skip: !(comparison && segmentId && !isMonthComparison) },
  ) as any;

  let comparisonSpeedData;
  if (comparisonDaySpeedData) {
    comparisonSpeedData = comparisonDaySpeedData;
  } else {
    comparisonSpeedData = typicalSpeedData;
  }

  return {
    speedData,
    comparisonSpeedData,
  };
}
