import React, { useEffect, useState } from 'react';

import {
  useGetDatesQuery,
  useGetPlanningPeriodsQuery,
  useGetTypicalMonthsQuery,
} from 'state/apiSlice';
import { useSelector } from 'react-redux';
import { selectProject, selectWorkflow } from 'state/workflowSlice';
import LoadingSpinner from '../spinner/Spinner';
import styles from '../workspace/Workspace.module.css';
import { workflowLookup } from '../workflow_common/workflowLookup';

export enum ReliesOn {
  nrtDay,
  typicalMonth,
  planningMonth,
}

export function useHasNoRelevantData() {
  const project = useSelector(selectProject);
  const workflow = useSelector(selectWorkflow);
  const workflowReliesOn =
    workflowLookup[workflow]?.dataAvailableReliesOn || [];
  const [hasNoRelevantData, setHasNoRelevantData] = useState(false);

  const polling: any = {};
  if (hasNoRelevantData) {
    polling.pollingInterval = 30 * 60 * 1000; // Poll every 30 minutes
  }
  const { data: datesInfo } = useGetDatesQuery(project, {
    skip: !project || !workflowReliesOn.includes(ReliesOn.nrtDay),
    ...polling,
  });
  const { data: planningMonthsInfo } = useGetPlanningPeriodsQuery(project, {
    skip: !project || !workflowReliesOn.includes(ReliesOn.planningMonth),
    ...polling,
  });
  const { data: monthComparisonInfo } = useGetTypicalMonthsQuery(project, {
    skip: !project || !workflowReliesOn.includes(ReliesOn.typicalMonth),
    ...polling,
  });
  useEffect(() => {
    const hasNoNrtData = datesInfo !== undefined && datesInfo.length === 0;
    const hasNoPlanningMonths =
      planningMonthsInfo !== undefined && planningMonthsInfo.length === 0;
    const hasNoTypicalMonths =
      monthComparisonInfo !== undefined && monthComparisonInfo.length === 0;
    setHasNoRelevantData(
      hasNoNrtData || hasNoPlanningMonths || hasNoTypicalMonths,
    );
  }, [datesInfo, planningMonthsInfo, monthComparisonInfo]);
  return hasNoRelevantData;
}

export function NoDataLoadedWarning() {
  return (
    <div className={styles.nodata}>
      <LoadingSpinner large />
      <p>
        <b>Project data is currently loading, this may take several hours</b>
        <br />
        Please contact support@streetlightdata.com if you have any questions.
      </p>
    </div>
  );
}
