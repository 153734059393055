import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AppsIcon from '@mui/icons-material/Apps';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import {
  PRODUCT_DETAILS,
  ProductInfo,
  ProductType,
} from 'features/app_header/productSwitcherConstants';
import { selectUserProducts } from 'state/userSlice';
import styles from './ProductSwitcher.module.css';

export function ProductSwitcher() {
  const products = useSelector(selectUserProducts);
  const [switcherOpen, setSwitcherOpen] = useState(false);
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  if (!products) {
    return null;
  }
  // Deduplicate products, prioritizing unexpired subscriptions
  const realized_products: { [key: string]: ProductInfo } = {};
  const seen_planning_orgs = [];
  const seen_orgs = [];
  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    const p_type = product.product_type;
    if (!product.subscription_expired && p_type === ProductType.insight_full) {
      seen_planning_orgs.push(product.org_id);
    }
    seen_orgs.push(product.org_id);
    if (realized_products[p_type]) {
      if (realized_products[p_type].subscription_expired) {
        realized_products[p_type] = product;
      }
    } else {
      realized_products[p_type] = product;
    }
  }
  const products_to_show = Object.values(realized_products);

  const seen_orgs_uniq = [...new Set(seen_orgs)];
  const seen_planning_orgs_uniq = [...new Set(seen_planning_orgs)];

  const get_patched_url = (product: ProductInfo) => {
    // This is a short term fix to make up for insight shortcomings: INST-39853
    let url = product.product_url;
    if (
      product.product_type === ProductType.insight_full &&
      seen_orgs_uniq.length > 0
    ) {
      if (seen_planning_orgs_uniq.length === 1) {
        // But if there's only one planning org, we can switch directly to it
        url += `?org_id=${product.org_id}`;
      } else {
        // If there are multiple orgs in play, patch the URL so going back to planning gets you the org chooser
        url = url.replace('/planning-solutions', '');
        url += '/chooseOrg';
      }
    }
    return url;
  };

  if (products_to_show.length <= 1) {
    return null;
  }
  return (
    <div className={styles.platform_switcher_base}>
      <IconButton onClick={() => setSwitcherOpen(true)}>
        <AppsIcon />
      </IconButton>
      {switcherOpen && (
        <>
          <div // eslint-disable-line jsx-a11y/no-static-element-interactions
            className={styles.click_catcher}
            onClick={() => setSwitcherOpen(false)}
            onKeyUp={(ev) => {
              if (ev.key === 'Escape') {
                setSwitcherOpen(false);
              }
            }}
          />
          <div className={styles.platform_switcher_body}>
            <span>Your Apps</span>
            <div className={styles.app_collection}>
              {products_to_show.map((product) => {
                const isDisabled = product.subscription_expired;
                return (
                  <div
                    className={styles.app_button_container}
                    title={
                      isDisabled
                        ? 'Subscription Expired, please contact support'
                        : undefined
                    }
                  >
                    <Button
                      href={get_patched_url(product)}
                      className={styles.app_button}
                      startIcon={PRODUCT_DETAILS[product.product_type].image}
                      disabled={isDisabled}
                    >
                      {product.product_name}
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
