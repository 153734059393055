import { useContext, useMemo } from 'react';
import { RouteInfo } from 'appTypes';
import { isArray } from 'highcharts';
import { SavedRoute, useGetSavedRouteQuery } from 'state/savedRouteSlices';
import { useSelector } from 'react-redux';
import {
  selectComparison,
  selectProject,
  selectRouteSelection,
  selectTargetDate,
} from 'state/workflowSlice';
import { LayerContext } from 'state/LayerContext';
import { useGetLayerQuery, useGetRouteInfoQuery } from 'state/apiSlice';
import { getRouteForSparseSegments } from 'features/map/mapUtils';

export function routeSelectionIsSavedRoute(routeSelection) {
  return !isArray(routeSelection);
}

export type BasicRouteInfo = {
  isSavedRoute: boolean;
  realizedSegments: string[];
  waypointSegments: string[];
};

export type UseRouteInfoReturnType = {
  feature: RouteInfo;
  savedRoute?: SavedRoute;
  route?: any;
  basicRouteInfo: BasicRouteInfo;
};

export function useRouteInfo(
  skip: boolean = false,
): UseRouteInfoReturnType | undefined {
  const project_slug = useSelector(selectProject);
  const routeSelection = useSelector(selectRouteSelection);
  const isSavedRoute = routeSelectionIsSavedRoute(routeSelection);
  const { layer } = useContext(LayerContext);

  const { currentData: layerData } = useGetLayerQuery(layer, {
    skip: skip || !layer || !routeSelection,
  });

  const { currentData: savedRouteData } = useGetSavedRouteQuery(
    { project_slug, savedRouteId: routeSelection, layerId: layer },
    { skip: skip || !isSavedRoute || !routeSelection },
  );

  const basicRouteInfo = useMemo(() => {
    console.log('Recalc basic route info');

    let realizedSegments = savedRouteData?.savedRoute?.realizedSegments;
    let waypointSegments = savedRouteData?.savedRoute?.routeSegmentIds;
    const isSavedRoute = routeSelectionIsSavedRoute(routeSelection);
    if (!isSavedRoute) {
      waypointSegments = routeSelection;
      if (layerData && routeSelection) {
        realizedSegments = getRouteForSparseSegments(
          layerData.routing,
          routeSelection,
        );
      }
    }

    return {
      isSavedRoute,
      realizedSegments,
      waypointSegments,
    };
  }, [savedRouteData, routeSelection, layerData]);

  const { currentData: routeData } = useGetRouteInfoQuery(
    {
      project_slug,
      segment_ids: basicRouteInfo.realizedSegments,
      layer_id: layer,
    },
    { skip: skip || isSavedRoute || !basicRouteInfo.realizedSegments },
  );

  const ret: UseRouteInfoReturnType = { basicRouteInfo, feature: undefined };

  if (isSavedRoute) {
    ret.feature = savedRouteData?.feature;
    ret.savedRoute = savedRouteData?.savedRoute;
  } else {
    ret.feature = routeData?.feature;
    ret.route = routeData?.route;
  }

  return ret;
}
