import React, {
  createContext,
  useRef,
  PropsWithChildren,
  useMemo,
  MutableRefObject,
  useState,
} from 'react';
import mapboxgl from 'mapbox-gl';

interface MapContextType {
  refMap: MutableRefObject<mapboxgl.Map | null>;
  mapReady: boolean;
  setMapReady: (ready: boolean) => void;
  refStyleReady: MutableRefObject<boolean>;
}

export const MapContext = createContext<MapContextType | null>(null);

export function MapContextProvider({ children }: PropsWithChildren<{}>) {
  const refMap = useRef<mapboxgl.Map>(null);
  const [mapReady, setMapReady] = useState(false);
  const refStyleReady = useRef(false);

  const contextValue = useMemo(
    () => ({ refMap, mapReady, setMapReady, refStyleReady }),
    [refMap, mapReady, refStyleReady],
  );

  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
}
