import React, { memo } from 'react';

export function SvgChevronInner(
  props: {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    zigHeight: number;
  } & React.SVGProps<SVGPathElement>,
) {
  const { x1, x2, y1, y2, zigHeight } = props;
  const halfx = (x1 + x2) / 2;
  return (
    <path
      d={`M ${x1} ${y1} L ${halfx} ${
        y1 - zigHeight
      } L ${x2} ${y1} L ${x2} ${y2} L ${halfx} ${
        y2 - zigHeight
      } L ${x1} ${y2} L ${x1} ${y1}`}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}

function SvgHatInner(
  props: {
    x1: number;
    x2: number;
    y: number;
    zigHeight: number;
  } & React.SVGProps<SVGPathElement>,
) {
  const { x1, x2, y, zigHeight } = props;
  const halfx = (x1 + x2) / 2;
  return (
    <path
      d={`M ${x1} ${y} L ${halfx} ${y - zigHeight} L ${x2} ${y}`}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}

export const SvgChevron = memo(SvgChevronInner);
export const SvgHat = memo(SvgHatInner);
