import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
  Feature,
  FeatureCollection,
  LineString,
  MultiLineString,
} from 'geojson';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { Grid } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { localPoint } from '@visx/event';

import mapboxgl from 'mapbox-gl';
import { capitalize } from '@mui/material';

import { MapContext } from 'features/map/MapContext';
import {
  getGeoJSONLayerData,
  getNearestSegmentFromMouseEvent,
  kBookmarkLayerDefinition,
  kBookmarkLayerId,
  kSelectedRouteLayerId,
  mapHasLayer,
  mapLayerSafeRemove,
  mapSourceSafeRemove,
  showBookmarks,
} from 'features/map/mapCommon';
import {
  interpAlongLineFeature,
  latLngToJsonPoint,
} from 'features/map/mapUtils';
import { stl_blue_color, stl_gold_color } from 'theme/cemTheme';
import {
  addQueuesToMap,
  getHoverOffsetFromMapMouseMove,
  setMapQueuesHoverId,
  updateHoverOffsetOnMap,
} from 'features/workflow_queuing/queuingMapBehaviour';
import {
  selectComparison,
  selectCurrentProjectInfo,
  selectProject,
  selectRouteSelection,
  selectShortestAvailableBin,
  selectTargetDate,
} from 'state/workflowSlice';
import {
  formatDistance,
  formatSpeed,
  formatSpeedKmph,
  KM_TO_MILES,
} from 'features/common/utils';
import style from './WorkflowQueuing.module.css';
import { isComparisonMonth } from '../task_bar/ComparisonPicker';
import { useRouteInfo } from '../common/useRouteInfo';
import { useGetRouteQueuesQuery } from '../../state/apiSlice';
import {
  formatTimeOfDay,
  max_slots,
  slots_to_minutes,
  ts_to_minutes,
  ts_to_slots,
} from '../workflow_timeline/slotsUtils';
import { formatTraveltime } from '../chart/TravelTimeChart';
import { SvgChevron, SvgHat } from './svgElements';

const prettyRed = '#F24D42';
const prettyOrange = '#FFCF44';
const prettyGreen = '#1B9C6F';
const transparent_black = '#00000000';

const QUEUE_CHART_CONTAINER_MARGIN = 10;
const QUEUE_CHART_MARGINS = { top: 20, bottom: 20, left: 40, right: 20 };

export enum TagmaState {
  CONGESTED = 'CONGESTED',
  QUEUED = 'QUEUED',
}

export const tagmaStateColors = {
  [TagmaState.CONGESTED]: prettyOrange,
  [TagmaState.QUEUED]: prettyRed,
};

export type RouteQueueTagmaProperties = {
  arthropod_id: number;
  tagma_id: number;
  timestep_at_tz: string;
  state: string;
  agg_atypical_delay_min: number;
  agg_geom_length_km: number;
  offset_start_km: number;
  offset_end_km: number;
};

export type RouteQueueSegmentLineProperties = {
  arthropod_id: number;
  tagma_id: number;
  timestep_at_tz: string;
  seq: number;
  osm_segment_id: string;
  geom_length_km: number;
  state: string;
  atypical_delay_min: number;
  freeflow_speed_kmph: number;
  average_speed_kmph: number;
  average_speed_kmph_null_is_probably_clogged: boolean;
  typical_speed_kmph: number;
  offset_start_km: number;
  offset_end_km: number;
};

export type RouteQueueTagma = Feature<
  LineString | MultiLineString,
  RouteQueueTagmaProperties | RouteQueueSegmentLineProperties
>;

export type RouteQueueData = {
  tagmas: FeatureCollection<
    LineString | MultiLineString,
    RouteQueueTagmaProperties
  >;
  segment_times: FeatureCollection<
    LineString | MultiLineString,
    RouteQueueSegmentLineProperties
  >;
};

type TooltipData = {
  tagma: RouteQueueTagma;
};

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};
const compose = (scale, accessor) => (data) => scale(accessor(data));
const kQueueChartContainerId = 'queue-chart-container';

const congestionScale = scaleLinear({
  domain: [0, 0.5, 1],
  range: [prettyRed, prettyOrange, prettyGreen],
});
const cong_color = (d) => {
  if (d.properties.average_speed_kmph !== null) {
    return congestionScale(
      d.properties.average_speed_kmph / d.properties.freeflow_speed_kmph,
    );
  }
  // Assume the missing data is a clogged road
  if (d.properties.average_speed_kmph_null_is_probably_clogged) {
    return '#913030';
  }
  // Assume the missing data is an empty road
  return '#249170';
};

export function QueuingChart() {
  const userProject = useSelector(selectCurrentProjectInfo);
  const project_slug = useSelector(selectProject);
  const targetDate = useSelector(selectTargetDate);
  const comparison = useSelector(selectComparison);
  const binSize = useSelector(selectShortestAvailableBin);
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();
  const refTooltipTimeout = useRef(null);
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const { refMap, mapReady } = useContext(MapContext);
  const routeInfo = useRouteInfo();
  const { data: queue_data } = useGetRouteQueuesQuery(
    {
      project_slug,
      routeIds: routeInfo.basicRouteInfo.realizedSegments,
      date: targetDate,
      comparison_ym: comparison,
    },
    {
      skip:
        !isComparisonMonth(comparison) ||
        !routeInfo.basicRouteInfo.realizedSegments,
    },
  );
  const feature_collection = queue_data?.tagmas;
  const segment_times = queue_data?.segment_times;

  const data = segment_times?.features || [];
  const [hoverOffset, setHoverOffset] = useState<null | number>(null);
  const [hoverQueueId, setHoverQueueId] = useState<null | number>(null);
  const [containerWidth, setContainerWidth] = useState<null | number>(null);
  const [containerHeight, setContainerHeight] = useState<null | number>(null);
  const [routeLength, setRouteLength] = useState<null | number>(null);

  // Indexers
  const get_queue_id = (d: RouteQueueTagma) => d.properties.arthropod_id;
  const get_minute_offset = (d: RouteQueueTagma) =>
    ts_to_minutes(d.properties.timestep_at_tz.replace('Z', ''), targetDate);
  const get_offset_start = (d: RouteQueueTagma) => d.properties.offset_start_km;
  const get_offset_end = (d: RouteQueueTagma) => d.properties.offset_end_km;
  const get_state = (d: RouteQueueTagma) => d.properties.state;
  const get_congestion = (
    d: Feature<LineString, RouteQueueSegmentLineProperties>,
  ) => {
    if (d.properties.average_speed_kmph !== null) {
      return d.properties.average_speed_kmph / d.properties.freeflow_speed_kmph;
    }

    // Assume the missing data is a clogged road
    if (d.properties.average_speed_kmph_null_is_probably_clogged) {
      return 0;
    }

    // Assume the missing data is an empty road
    return 1;
  };

  const queues = useMemo(() => {
    const queues = {};
    for (let i = 0; i < data.length; i++) {
      const queue_id = get_queue_id(data[i]);
      if (queue_id !== null) {
        queues[queue_id] = queues[queue_id] || [];
        queues[queue_id].push(data[i]);
      }
    }
    return queues;
  }, [data]);

  const width = containerWidth - 2 * QUEUE_CHART_CONTAINER_MARGIN;
  const height = containerHeight - 2 * QUEUE_CHART_CONTAINER_MARGIN;

  useEffect(() => {
    let inner_route_length;
    if (routeInfo.feature) {
      inner_route_length = routeInfo.feature.properties.route_length * 0.001;
    }
    if (inner_route_length !== routeLength) {
      setRouteLength(inner_route_length);
    }
  }, [routeInfo]);

  // Then we'll create some bounds

  const { xMax, yMax, xDomain, xScale, yScale } = useMemo(() => {
    console.log('Redo scales...');
    const xMax = width - QUEUE_CHART_MARGINS.left - QUEUE_CHART_MARGINS.right;
    const yMax = height - QUEUE_CHART_MARGINS.top - QUEUE_CHART_MARGINS.bottom;

    const xDomain = [];
    for (let i = 0; i <= max_slots(binSize); i++) {
      xDomain.push(slots_to_minutes(i, binSize));
    }
    const xScale = scaleBand({
      range: [0, xMax],
      round: false,
      domain: xDomain,
      padding: 0,
    });
    const yScale = scaleLinear({
      range: [yMax, 0],
      round: true,
      domain: [0, routeLength],
    });
    return { xMax, yMax, xDomain, xScale, yScale };
  }, [width, height, QUEUE_CHART_MARGINS, binSize, routeLength]);

  const get_total_queue_info = (queue_id) => {
    const queue = queues[queue_id] || [];
    let len = 0;
    let delay = 0;
    for (let i = 0; i < queue.length; i++) {
      const tigma = queue[i];
      len += get_offset_end(tigma) - get_offset_start(tigma);
      delay += tigma.properties.atypical_delay_min;
    }
    return {
      total_length: len,
      total_delay: delay,
    };
  };

  const xPoint = compose(xScale, get_minute_offset);
  const y_high = compose(yScale, get_offset_end);
  const y_low = compose(yScale, get_offset_start);

  const { renderableSegs } = useMemo(() => {
    console.debug('Recalc renderable segs');
    const renderableSegs = (queue_data?.segment_times?.features || []).map(
      (v) => {
        return {
          x1: xPoint(v),
          x2: xPoint(v) + xScale.bandwidth(),
          y1: y_high(v),
          y2: y_low(v),
          color: cong_color(v),
          ...v,
        };
      },
    );

    return { renderableSegs };
  }, [queue_data, xScale, yScale]);

  useEffect(() => {
    if (mapReady) {
      updateHoverOffsetOnMap(refMap.current, hoverOffset);
    }
  }, [hoverOffset, mapReady]);

  const onMapMouseMoveHandler = (event: mapboxgl.MapMouseEvent) => {
    setHoverOffset(getHoverOffsetFromMapMouseMove(refMap.current, event));
  };

  useEffect(() => {
    if (mapReady) {
      refMap.current.on('mousemove', onMapMouseMoveHandler);
    }
    return () => {
      if (refMap.current) {
        refMap.current.off('mousemove', onMapMouseMoveHandler);
      }
    };
  }, [mapReady]);

  useEffect(() => {
    if (mapReady && feature_collection) {
      addQueuesToMap(refMap.current, feature_collection, hoverQueueId);
    }
  }, [mapReady, feature_collection]);

  useEffect(() => {
    setMapQueuesHoverId(refMap.current, hoverQueueId);
  }, [hoverQueueId]);

  const handleGraphMouseMoveBase = (
    event: React.MouseEvent,
    tagma?: RouteQueueTagma,
  ) => {
    const newOffset = yScale.invert(
      localPoint(event).y - QUEUE_CHART_MARGINS.top,
    );
    if (newOffset >= 0) {
      setHoverOffset(newOffset);
    } else {
      setHoverOffset(null);
    }

    if (tagma) {
      setHoverQueueId(get_queue_id(tagma));
      console.log(tagma, queues[get_queue_id(tagma)]);
    } else {
      setHoverQueueId(null);
    }
  };

  const makeHandleGraphMouseMoveOnTagma = (tagma: RouteQueueTagma) => {
    return (event: React.MouseEvent) => {
      handleGraphMouseMoveBase(event, tagma);
      if (refTooltipTimeout.current) clearTimeout(refTooltipTimeout.current);
      // TooltipInPortal expects coordinates to be relative to containerRef
      // localPoint returns coordinates relative to the nearest SVG, which
      // is what containerRef is set to in this example.
      const eventSvgCoords = localPoint(event);
      let left =
        xPoint(tagma) + QUEUE_CHART_MARGINS.left + xScale.bandwidth() * 1;
      left = Math.max(Math.min(left, xMax), 0);
      showTooltip({
        tooltipData: { tagma },
        tooltipTop: eventSvgCoords?.y,
        tooltipLeft: left,
      });
    };
  };

  const handleWindowResize = () => {
    const el = document.getElementById(kQueueChartContainerId);
    const { width, height } = el.getBoundingClientRect();
    setContainerHeight(height);
    setContainerWidth(width);
  };

  useEffect(() => {
    // Add resize event listener
    document.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  let graph;
  if (containerWidth) {
    graph = (
      <>
        <svg ref={containerRef} width={width} height={height}>
          <Group left={QUEUE_CHART_MARGINS.left} top={QUEUE_CHART_MARGINS.top}>
            <Grid
              top={0}
              left={0}
              xScale={xScale}
              yScale={yScale}
              width={xMax}
              height={yMax}
              stroke="white"
              strokeOpacity={0.1}
              xOffset={xScale.bandwidth() / 2}
            />
            <line
              id="midnightLine1"
              x1={xScale(0) + xScale.bandwidth() / 2}
              x2={xScale(0) + xScale.bandwidth() / 2}
              y1={0}
              y2={yMax}
              stroke="white"
              strokeWidth="2px"
            />
            <line
              id="midnightLine2"
              x1={xScale(24 * 60) + xScale.bandwidth() / 2}
              x2={xScale(24 * 60) + xScale.bandwidth() / 2}
              y1={0}
              y2={yMax}
              stroke="white"
              strokeWidth="2px"
            />
            {hoverOffset && (
              <line
                id="hoverOffsetLine"
                x1={0}
                x2={xMax}
                y1={yScale(hoverOffset)}
                y2={yScale(hoverOffset)}
                stroke="white"
                strokeWidth="2px"
              />
            )}
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              opacity={0}
              rx={14}
              onMouseMove={handleGraphMouseMoveBase}
              onMouseLeave={(event) => {
                setHoverOffset(null);
              }}
            />
            {renderableSegs.map((tagma, index) => {
              return (
                <SvgChevron
                  x1={tagma.x1}
                  x2={tagma.x2}
                  y1={tagma.y1}
                  y2={tagma.y2}
                  fill={tagma.color}
                  zigHeight={5}
                  opacity={
                    get_queue_id(tagma) === hoverQueueId // eslint-disable-line no-nested-ternary
                      ? 1.0
                      : !hoverQueueId
                      ? 1.0
                      : 0.8
                  }
                  onMouseLeave={() => {
                    refTooltipTimeout.current = window.setTimeout(() => {
                      hideTooltip();
                    }, 300);
                  }}
                  onMouseMove={makeHandleGraphMouseMoveOnTagma(tagma)}
                />
              );
            })}
            {Object.values(queues).map((tagmas: RouteQueueTagma[], index) => {
              const start = tagmas[0];
              const end = tagmas[tagmas.length - 1];
              return (
                <>
                  <SvgHat
                    x1={xPoint(start)}
                    x2={xPoint(start) + xScale.bandwidth()}
                    y={y_high(start)}
                    zigHeight={5}
                    fill="none"
                    stroke="white"
                    strokeWidth="2px"
                  />
                  <SvgHat
                    x1={xPoint(end)}
                    x2={xPoint(end) + xScale.bandwidth()}
                    y={y_low(end)}
                    zigHeight={5}
                    fill="none"
                    stroke="white"
                    strokeWidth="2px"
                  />
                </>
              );
            })}
            <AxisBottom
              top={yMax}
              scale={xScale}
              tickFormat={formatTimeOfDay}
              stroke="white"
              tickStroke="white"
              tickLabelProps={{
                fill: 'white',
                fontSize: 11,
                textAnchor: 'middle',
                dy: '0em',
              }}
            />
            <AxisLeft
              scale={yScale}
              stroke="white"
              tickStroke="white"
              tickLabelProps={{
                fill: 'white',
                fontSize: 11,
                textAnchor: 'middle',
                dx: '-1em',
              }}
            />
            <line
              id="axisLeftLine"
              x1={0}
              x2={0}
              y1={0}
              y2={yMax}
              stroke="white"
              strokeWidth="2px"
            />

            <line
              id="axisBottomLine"
              x1={0}
              x2={xMax}
              y1={yMax}
              y2={yMax}
              stroke="white"
              strokeWidth="2px"
            />
          </Group>
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <div className={style.chart_tooltip_container}>
              <strong>
                {formatTimeOfDay(get_minute_offset(tooltipData.tagma))}
                {' - '}
                {capitalize(tooltipData.tagma?.properties?.state.toLowerCase())}
              </strong>
              <div>
                <p>
                  Length:{' '}
                  {formatDistance(
                    get_total_queue_info(get_queue_id(tooltipData.tagma))
                      .total_length * 1000,
                    userProject.uses_metric,
                  )}
                </p>
                <p>
                  Speed:
                  {formatSpeedKmph(
                    (tooltipData.tagma.properties as any).average_speed_kmph,
                    userProject.uses_metric,
                  )}
                </p>
                <p>
                  Delay:{' '}
                  {formatTraveltime(
                    get_total_queue_info(get_queue_id(tooltipData.tagma))
                      .total_delay * 60,
                  )}
                </p>
              </div>
            </div>
          </TooltipInPortal>
        )}
      </>
    );
  }

  return (
    <div
      id={kQueueChartContainerId}
      style={{ position: 'relative', height: '100%', width: '100%' }}
    >
      {graph}
    </div>
  );
}
