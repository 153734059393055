import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'state/hooks';

import { ProjectAppHeader } from 'features/app_header/AppHeader';
import { NavBar } from 'features/nav_bar/NavBar';
import { Workspace } from 'features/workspace/Workspace';
import './App.css';
import { kUserStatusLoggedIn } from 'state/userSlice';
import { setStateFromSearchParams } from 'state/workflowSlice';
import { useDispatchUserStatus } from './AppDispatcher';

export function AppUrlChangeListener() {
  const dispatch = useDispatch();

  const handlePopStateEvent = (event: PopStateEvent) => {
    dispatch(setStateFromSearchParams(window.location.search));
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopStateEvent);
    return () => window.removeEventListener('popstate', handlePopStateEvent);
  });
  return null;
}

export function App() {
  const dispatch = useDispatch();

  const userStatus = useAppSelector((state) => state.user.status);
  useDispatchUserStatus();
  const initialSearchParams = useAppSelector(
    (state) => state.user.initialSearchParams,
  );

  const [didInitSearchParams, setDidInitSearchParams] = useState(false);

  useEffect(() => {
    if (userStatus === kUserStatusLoggedIn && !didInitSearchParams) {
      dispatch(setStateFromSearchParams(initialSearchParams));
      setDidInitSearchParams(true);
    }
  }, [dispatch, userStatus, initialSearchParams, didInitSearchParams]);

  if (userStatus === kUserStatusLoggedIn && didInitSearchParams) {
    return (
      <div className="App">
        <ProjectAppHeader />
        <div className="App-body">
          <NavBar />
          <Workspace />
        </div>
      </div>
    );
  }
}
