import React from 'react';
import { CurrentUserResponse, ProjectInfo } from 'state/userSlice';
import Button from '@mui/material/Button';

import { ReactComponent as CircleQuestion } from './circle_question.svg';

export function WalkmeHelpButton() {
  return (
    <Button
      title="Help"
      aria-label="Help"
      id="help-actions-menuControl"
      aria-hidden="true"
      target="_blank"
      sx={{
        color: 'var(--primary-text)',
        backgroundColor: 'color-mix(in hsl, var(--blue-col), #00000000 80%)', // mostly-transparent blue
        ' svg': { color: 'var(--blue-col)' },
      }}
      startIcon={<CircleQuestion width="20px" />}
      href="https://help.streetlightdata.com/s/contactsupport"
    >
      Help
    </Button>
  );
}

class WalkmeService {
  isLocal = window.location.hostname.includes('localhost');

  get walkmeVariables() {
    // @ts-ignore Property 'WALKME_VARIABLES' does not exist on type 'Window & typeof globalThis'.
    return window.WALKME_VARIABLES;
  }

  set walkmeVariables(value) {
    // @ts-ignore Property 'WALKME_VARIABLES' does not exist on type 'Window & typeof globalThis'.
    window.WALKME_VARIABLES = value;
  }

  resetWalkmeVariables() {
    if (!this.isLocal) {
      this.walkmeVariables = {};
    }
  }

  addWalkmeVariables(currentUser: CurrentUserResponse) {
    if (!this.isLocal) {
      this.walkmeVariables = {
        email: currentUser.email,
        user_id: currentUser.username,
        user_name: currentUser.email,
        actual_user_id: currentUser.username,
        effective_user_id: currentUser.username,
        actual_user_email: currentUser.email,
        effective_email_address: currentUser.email,
        is_emulating: false,
        url_subdomain: window.location.hostname.split('.')[0],
      };
    }
  }
  setProjectVariables(project_info: ProjectInfo) {
    if (project_info && !this.isLocal) {
      this.walkmeVariables = {
        ...this.walkmeVariables,
        org_id: project_info.org_id,
        org_name: project_info.org_name,
      };
    }
  }
}

export const walkmeService = new WalkmeService();
