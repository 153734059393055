export enum WorkflowType {
  Plan = 'T',
  TravelTime = 'TT',
  Speed = 'RT',
  Timeline = 'TL',
  Queuing = 'Q',
}

export namespace WorkflowType {
  export function isTimelineIsh(workflowType: WorkflowType) {
    return [WorkflowType.Timeline].includes(workflowType);
  }
}

export const restApiUrl = '/server';

// user visible names
export const WORKFLOW_PLAN_NAMES = {
  [WorkflowType.Timeline]: 'Timeline',
  [WorkflowType.Plan]: 'Volumes',
  [WorkflowType.Speed]: 'Speeds',
  [WorkflowType.TravelTime]: 'Travel Time',
  [WorkflowType.Queuing]: 'Queuing',
};

/**
 * Defines the Navbar order.
 */
export const WORKFLOWS_ALL_ENABLED = [
  WorkflowType.Timeline,
  WorkflowType.Plan,
  WorkflowType.Speed,
  WorkflowType.TravelTime,
  WorkflowType.Queuing,
];

export const MAXIMIZE_MAP = 'map';
export const MAXIMIZE_DATA = 'data';

export const BIN_15_MINS = '15';
export const BE_BIN_15_MINS = 15;
export const BIN_60_MINS = '60';
export const BE_BIN_60_MINS = 60;

export const VIZ_MODE_CHART = 'chart';
export const VIZ_MODE_TABLE = 'table';

export const COMPARISON_MODE_MONTH = 'month';
export const COMPARISON_MODE_DAY = 'day';

export enum MapStyle {
  Dark = 'dark',
  Sat = 'sat',
}

export const mapboxApiKey =
  'pk.eyJ1IjoicGZyaWVkbWFuIiwiYSI6ImNscHVjb2xjcjBqNmwyanBscWMwNWZiZnUifQ.QRTJ67oscepSyJARJ-Pgug';

export enum DayTypes {
  Weekday = 'Weekday',
  MeWeekday = 'MeWeekday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}
