import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSearchParams } from 'react-router-dom';

dayjs.extend(customParseFormat);

export function parseDate(YYYY_MM_DD: string): dayjs.Dayjs {
  return dayjs(YYYY_MM_DD, 'YYYY-MM-DD');
}

export function displayFriendlyDate(YYYY_MM_DD: string): string {
  // convert ISO to USA format: YYYY-MM-DD -> MM/DD/YYYY
  return dayjs(YYYY_MM_DD, 'YYYY-MM-DD').format('MMMM DD YYYY');
}

export function displayDate(YYYY_MM_DD: string): string {
  // convert ISO to USA format: YYYY-MM-DD -> MM/DD/YYYY
  return YYYY_MM_DD.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$2/$3/$1');
}

export function displayTypicalMonth(MMMM_YYYY: string) {
  return dayjs(MMMM_YYYY, 'MMMM-YYYY').format('MMMM YYYY');
}

export function isOnDay(ts: number, date: string) {
  const query = new Date(ts).setHours(0, 0, 0, 0);
  if (query === parseDate(date).toDate().setHours(0, 0, 0, 0)) {
    return true;
  }
  return false;
}

export function displayBinSize(binSize: number) {
  if (binSize) {
    if (binSize === 60) {
      return 'Hourly';
    }
  }
  return binSize && `${binSize} minute`;
}

export function isMobileOrTablet() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
    // @ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

//  pure js to rewrite url in place, no hooks attached
export function replaceOrPushURLParam(searchParams, pushOnChangeTo: string[]) {
  const newRelativePathQuery = `${
    window.location.pathname
  }?${searchParams.toString()}`;
  const currentSearchParams = new URLSearchParams(window.location.search);
  // console.log(currentSearchParams, searchParams);
  let shouldPush = false;
  for (let i = 0; i < pushOnChangeTo.length; i++) {
    const key = pushOnChangeTo[i];
    // Only push if we previously had this key and it has now changed
    // This is slightly strange logic but it prevents pushing a load of history on app reload where state is being trickled in from components
    if (
      !!currentSearchParams.get(key) &&
      currentSearchParams.get(key) !== searchParams.get(key)
    ) {
      // console.log(key, currentSearchParams.get(key), searchParams.get(key));
      shouldPush = true;
    }
  }
  const currentSearchString = window.location.search
    .toString()
    .replace('?', '');
  if (searchParams.toString() !== currentSearchString) {
    // console.log(shouldPush, newRelativePathQuery);
    if (shouldPush) {
      window.history.pushState(null, '', newRelativePathQuery);
    } else {
      window.history.replaceState(null, '', newRelativePathQuery);
    }
  } else {
    // console.log('Updating duplicate url entry, skipping');
  }
}

export function resetURLParams() {
  // console.log(`resetURLParams`);
  window.history.pushState(null, '', window.location.pathname);
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function optionallyCall(value, args) {
  if (typeof value === 'function') {
    return value(args);
  } else {
    return value;
  }
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    const keys = Object.keys(source);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { key: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { key: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function sortTypicalMonths(typicalMonths) {
  const months = [...typicalMonths];
  months.sort(
    (a, b) =>
      dayjs(a.typicalMonth, 'MMMM-YYYY').valueOf() -
      dayjs(b.typicalMonth, 'MMMM-YYYY').valueOf(),
  );
  return months;
}
