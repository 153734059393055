import { workflowSpeed } from 'features/workflow_nrt/WorkflowSpeed';
import { workflowPlanning } from 'features/workflow_planning/WorkflowPlanning';
import { WorkflowType } from '../../appConstants';
import { workflowTimeline } from '../workflow_timeline/WorkflowTimeline';
import { workflowTravelTime } from '../workflow_traveltime/WorkflowTravelTime';
import { ReliesOn } from './NoDataLoadedWarning';

export type WorkflowInfo = {
  switchToState: (fromWorkflow: WorkflowType, state: any) => void;
  dataAvailableReliesOn: ReliesOn[];
};

export const workflowLookup: { [k: string]: WorkflowInfo } = {
  [WorkflowType.Timeline]: workflowTimeline,
  [WorkflowType.Speed]: workflowSpeed,
  [WorkflowType.TravelTime]: workflowTravelTime,
  [WorkflowType.Plan]: workflowPlanning,
};
