import {
  getGeoJSONLayerData,
  getNearestSegmentFromMouseEvent,
  kSegmentLayerId,
  kSelectedRouteLayerId,
  line_width_expression,
  mapHasLayer,
  mapLayerSafeRemove,
  mapSourceSafeRemove,
  selection_line_width_expression,
  thick_line_width_expression,
} from 'features/map/mapCommon';
import {
  interpAlongLineFeature,
  latLngToJsonPoint,
} from 'features/map/mapUtils';
import { Feature, FeatureCollection, LineString } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { kQueuePositionMarkerImg } from 'features/map/MapIconProvider';
import { stl_gold_color } from 'theme/cemTheme';
import * as turf from '@turf/turf';
import { useEffect } from 'react';
import {
  TagmaState,
  tagmaStateColors,
} from 'features/workflow_queuing/QueuingChart';

const kMapHoverSourceId = 'maphover';
const kMapHoverLayerId = 'maphover';
const kQueueLayerId = 'queue';

export const kMapHoverLayerDefinition: mapboxgl.SymbolLayer = {
  id: kMapHoverLayerId,
  type: 'symbol',
  source: kMapHoverSourceId,
  layout: {
    'icon-image': kQueuePositionMarkerImg,
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-size': 0.6,
  },
  paint: {
    'icon-color': 'white',
    'icon-opacity': 1,
  },
};

export function updateHoverOffsetOnMap(
  map: mapboxgl.Map,
  hoverOffset: null | number,
) {
  if (!hoverOffset && hoverOffset !== 0) {
    console.log('Drop');
    mapLayerSafeRemove(map, kMapHoverLayerId);
    mapSourceSafeRemove(map, kMapHoverSourceId);
  } else {
    const existingRoute = getGeoJSONLayerData(map, kSelectedRouteLayerId);
    if (existingRoute) {
      const interpolated = interpAlongLineFeature(
        existingRoute as Feature<LineString>,
        hoverOffset,
      );
      if (interpolated) {
        const bookmarks: FeatureCollection = {
          type: 'FeatureCollection',
          features: [interpolated],
        } as FeatureCollection;
        if (!mapHasLayer(map, kMapHoverLayerId)) {
          map.addSource(kMapHoverSourceId, {
            type: 'geojson',
            data: bookmarks,
          });
          map.addLayer(kMapHoverLayerDefinition);
        }
        (map.getSource(kMapHoverSourceId) as any).setData(bookmarks);
      }
    }
  }
}

export function getHoverOffsetFromMapMouseMove(
  map: mapboxgl.Map,
  event: mapboxgl.MapMouseEvent,
) {
  const nearest = getNearestSegmentFromMouseEvent(
    map,
    event,
    kSelectedRouteLayerId,
  );
  if (!nearest) {
    return null;
  }
  const nearest_point = turf.nearestPointOnLine(
    nearest,
    latLngToJsonPoint(event.lngLat),
  );
  const split_start = turf.lineSplit(nearest, nearest_point).features[0];
  const split_length = turf.length(split_start);
  return split_length;
}

export function filterForQueueId(queueId: null | number) {
  if (!queueId) return ['boolean', false];
  return ['==', ['get', 'arthropod_id'], queueId];
}

export function addQueuesToMap(
  map,
  queue_feature_collection,
  hoverQueueId: number,
) {
  mapLayerSafeRemove(map, kQueueLayerId);
  mapSourceSafeRemove(map, kQueueLayerId);

  map.addSource(kQueueLayerId, {
    type: 'geojson',
    data: queue_feature_collection,
  });

  map.addLayer({
    id: kQueueLayerId,
    type: 'line',
    source: kQueueLayerId,
    layout: {
      'line-cap': 'round',
    },
    paint: {
      'line-color': [
        'match',
        ['get', 'state'],
        'CONGESTED',
        tagmaStateColors[TagmaState.CONGESTED],
        'QUEUED',
        tagmaStateColors[TagmaState.QUEUED],
        'rgba(0, 235, 196, 0.8)',
      ],
      'line-width': selection_line_width_expression,
    },
    filter: filterForQueueId(hoverQueueId),
  });
}

export function setMapQueuesHoverId(map: mapboxgl.Map, hoverQueueId: number) {
  if (map && mapHasLayer(map, kQueueLayerId)) {
    map.setFilter(kQueueLayerId, filterForQueueId(hoverQueueId));
  }
}
