import React from 'react';
import { useSelector } from 'react-redux';

import TableRow from '@mui/material/TableRow';
import { selectCurrentProjectInfo } from 'state/workflowSlice';

import { StyledCell } from './SegmentInfo';
import { formatDistance } from '../common/utils';
import { useRouteInfo } from '../common/useRouteInfo';
import { useTravelTimeDataProvider } from '../workflow_traveltime/nrtTravelTimeCommon';
import { formatTraveltime } from '../chart/TravelTimeChart';

export function RouteSegmentInfo() {
  const userProject = useSelector(selectCurrentProjectInfo);
  // ttData from REST api
  const { feature } = useRouteInfo();
  const { comparisonData } = useTravelTimeDataProvider();

  if (!feature || !feature.properties.route_length || !userProject) {
    return null;
  } else {
    return (
      <>
        <TableRow key="route_length">
          <StyledCell>Route Length</StyledCell>
          <StyledCell>
            {formatDistance(
              feature.properties.route_length,
              userProject.uses_metric,
            )}
          </StyledCell>
        </TableRow>
        {comparisonData?.freeflow_traveltime && (
          <TableRow key="freeflow_traveltime">
            <StyledCell>Freeflow traveltime</StyledCell>
            <StyledCell>
              {formatTraveltime(comparisonData?.freeflow_traveltime)}
            </StyledCell>
          </TableRow>
        )}
      </>
    );
  }
}
