import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loginSSO } from 'state/userSlice';

export async function handleSSOLogin(dispatch) {
  const response = await dispatch(
    // @ts-ignore
    // ! TS2345: Argument of type 'AsyncThunkAction<any, void, AsyncThunkConfig>' is not assignable to parameter of type 'AnyAction'.
    loginSSO(),
  ).unwrap();

  window.location.href = response.redirected;
}

export function SSOLogin() {
  const dispatch = useDispatch();
  useEffect(() => {
    handleSSOLogin(dispatch);
  }, []);
  return null;
}
