import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import mapboxgl from 'mapbox-gl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import styles from 'features/map/Map.module.css';

import {
  selectComparison,
  selectCurrentProjectInfo,
  selectMapCenter,
  selectMapStyle,
  selectMapZoom,
  selectProject,
  selectRouteSelection,
  selectSegmentId,
  selectShortestAvailableBin,
  selectShowIncidents,
  selectTargetDate,
  selectTimelineMetric,
  selectTimeOfDay,
  setMapCenter,
  setMapZoom,
  setRouteSelection,
  setSelectedSegmentId,
  setShowIncidents,
} from 'state/workflowSlice';
import { useGetLayerQuery } from 'state/apiSlice';

import { LayerContext } from 'state/LayerContext';

import { optionallyCall } from 'appUtils';
import {
  ensureRtlLoaded,
  latLngToJsonPoint,
  launderLatLng,
  toggleLayerVisibility,
} from 'features/map/mapUtils';
import {
  counts_absolute_size_expr,
  isRealtimeMetric,
  metricExprDeepSub,
  TL_METRIC_OPTIONS,
} from 'features/workflow_timeline/metricsOptions';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'features/map/Map.css';
import {
  kHalfDirectionArrowNameLHD,
  kHalfDirectionArrowNameRHD,
} from 'features/map/MapIconProvider';
import {
  getInitialCenter,
  getNearestSegmentFromMouseEvent,
  icon_size_expression,
  initMap,
  kMainVisLineOpacity,
  kMainVisLineOpacityExpression,
  kSegmentHoverId,
  kSegmentLayerArrowDefinition,
  kSegmentLayerId,
  kSegmentLayerSourceLayerId,
  kSegmentSourceId,
  kSegmentSymbolsLayerId,
  mapHasLayer,
  MapLoadingSpinner,
  mapPopSelectedSegment,
  mapShowingSegments,
  mapShowingSelectedSegment,
  OutsideBboxPrompt,
  redrawBaseSegments,
  safeRemoveBookmarks,
  showBookmarks,
  thick_line_width_expression,
  updateMapLocation,
} from 'features/map/mapCommon';
import {
  ToggleLayerControl,
  ToggleLayerControlValue,
} from 'features/map/mapControls';
import { transparent_null_grey } from 'theme/cemTheme';

import { TimelineLegend } from './TimelineLegend';
import { mapboxApiKey } from '../../appConstants';
import {
  selectDisplayedBookmark,
  selectInLocationEditMode,
  setDisplayedBookmark,
} from '../../state/bookmarkSlices';
import { FloatingDiv } from '../spinner/Spinner';
import { getMinuteTicker, useRealtimeData } from './RealtimeDataProvider';
import {
  selectRepresentedSlots,
  selectVnrtData,
  setRepresentedSlots,
} from '../../state/realtimeSlices';
import { useDayInfo } from '../task_bar/DatePicker';
import { useCallbackWithErrorHandling } from '../../app/ErrorHandling';
import { minutes_to_slots } from './slotsUtils';
import { MapSettings } from '../map/MapSettings';
import { ProjectFeatures, selectUserState } from '../../state/userSlice';
import {
  findNearestIncidentInBox,
  kIncidentsShowHideDisabledMessage,
  kIncidentsShowHideNormalMessage,
  redrawIncidents,
  setIncidentLayerVisibility,
  shouldShowIncidents,
} from '../map/incidentsLayer';
import { formatInfoToHtml } from './timelineTooltip';
import { MapContext } from '../map/MapContext';

dayjs.extend(utc);
mapboxgl.accessToken = mapboxApiKey;

const kColorUpdateMillis = 200;
const kMouseHoverTimeoutMillis = 300;
const kTicksShowHideId = 'ticks-showhide';
const kIncidentsShowHideId = 'incidents-showhide';
const kUpdateSlotsRepresentedTimeoutMs = 1000;

let lastColorUpdate;
let lastColorUpdateRef;

function toPrecisionUp(number, precision) {
  precision -= Math.floor(number).toString().length;
  const order = 10 ** precision;
  number *= order;
  return Math.ceil(number) / order;
}

export const timelineMinZoom = 12;

export function TimelineMap({
  alwaysUseHamburgerNav,
}: {
  alwaysUseHamburgerNav: boolean;
}) {
  // https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/

  const dispatch = useDispatch();

  // useRef DOM refs and for variables accessed from map callbacks
  const { refMap, mapReady, setMapReady, refStyleReady } =
    useContext(MapContext);
  const refPopup = useRef<mapboxgl.Popup>(null); // mapboxgl.Map object
  const refMapContainer = useRef(null); // DOM node reference
  const refRouting = useRef({});
  const refMouseoverTimeout = useRef(null);

  const hourlyMaxCount = useRef(1000);

  const [zoomPrompt, setZoomPrompt] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { layer } = useContext(LayerContext);
  const selectedSegmentId = useSelector(selectSegmentId);
  const selectedRoute = useSelector(selectRouteSelection);
  const project = useSelector(selectProject);
  const currentMapCenter = useSelector(selectMapCenter);
  const mapZoom = useSelector(selectMapZoom);
  const targetDate = useSelector(selectTargetDate);
  const timeOfDay = useSelector(selectTimeOfDay);
  const userProject = useSelector(selectCurrentProjectInfo);
  const binSize = useSelector(selectShortestAvailableBin);
  const timelineMetric = useSelector(selectTimelineMetric);
  const refTimelineMetric = useRef(timelineMetric);
  const comparison = useSelector(selectComparison);
  const inLocationEditMode = useSelector(selectInLocationEditMode);
  const displayedBookmark = useSelector(selectDisplayedBookmark);
  const vnrtData = useSelector(selectVnrtData);
  const refVnrtData = useRef(vnrtData);
  const refExpectingSDE = useRef(undefined); // We use this to debounce the fact that many things are causing a source data event that shouldn't
  const refEnrichedTiles = useRef({});
  const refUpdateSlotsRepresentedTimeout = useRef(null);
  const refTargetDate = useRef(targetDate);
  const refProjectUsesMetric = useRef(userProject?.uses_metric);
  const mapStyle = useSelector(selectMapStyle);
  const userInfo = useSelector(selectUserState);
  const showIncidents = useSelector(selectShowIncidents);
  const refShowIncidents = useRef(showIncidents);

  const timeOfDayOffset = minutes_to_slots(timeOfDay, binSize || 15);
  const timeOfDayOffsetRef = useRef({
    offset: timeOfDayOffset,
    time: timeOfDay,
  });
  timeOfDayOffsetRef.current = { offset: timeOfDayOffset, time: timeOfDay };
  const refInLocationEditMode = useRef(inLocationEditMode);

  // load segmentData for layer from REST api (or cache)
  const { currentData: layerData } = useGetLayerQuery(layer, { skip: !layer });
  const { day_info } = useDayInfo();
  const refDayInfo = useRef(day_info);
  const t = getMinuteTicker();
  const { realtime: realtimeData } = useRealtimeData(t);
  const refRealtimeData = useRef(realtimeData);

  // useSegmentSelectionCrosswalk();

  refRouting.current = useMemo(() => layerData?.routing, [layerData]);

  const projectHasIncidents = userProject.features.includes(
    ProjectFeatures.INCIDENTS,
  );

  ensureRtlLoaded();

  function getColorExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    const NULL_PLACEHOLDER = 1000000;
    if (!setting) {
      return undefined;
    }
    const settingsArgs = {
      timeOfDayOffset,
      hourlyMaxCount: Number(hourlyMaxCount.current),
      uses_metric: refProjectUsesMetric.current,
    };
    return [
      'interpolate',
      ['linear'],
      ['coalesce', setting.metric_expr(settingsArgs), NULL_PLACEHOLDER],
      ...optionallyCall(setting.colors, settingsArgs),
      NULL_PLACEHOLDER,
      transparent_null_grey,
    ];
  }

  function getShowExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    if (!setting) {
      return undefined;
    }
    const settingsArgs = {
      timeOfDayOffset,
      hourlyMaxCount: Number(hourlyMaxCount.current),
      uses_metric: refProjectUsesMetric.current,
    };
    if (!setting.shown) {
      return true;
    } else {
      const shown = metricExprDeepSub(
        [...setting.shown],
        setting.metric_expr(settingsArgs),
      );
      return shown;
    }
  }

  function getLineWidthExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    if (setting?.width === 'count_absolute') {
      const counts_expr = (base) =>
        counts_absolute_size_expr(
          timeOfDayOffset,
          Number(hourlyMaxCount.current),
          base,
        );
      return [
        'interpolate',
        ['linear'],
        ['zoom'],
        8,
        counts_expr(1),
        14,
        counts_expr(3),
        16,
        counts_expr(8),
        19,
        counts_expr(20),
      ];
    } else {
      return thick_line_width_expression;
    }
  }

  function getLineOffsetExpression() {
    let sign = -1;
    if (userProject?.right_hand_drive) {
      sign = 1;
    }
    return [
      'interpolate',
      ['linear'],
      ['zoom'],
      8,
      sign * 1,
      14,
      sign * 2,
      16,
      sign * 3,
      19,
      sign * 5,
    ];
  }

  function getIconSizeExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    if (setting?.width === 'count_absolute') {
      const counts_expr = (base) =>
        counts_absolute_size_expr(
          timeOfDayOffset,
          Number(hourlyMaxCount.current),
          base,
        );
      return [
        'interpolate',
        ['linear'],
        ['zoom'],
        8,
        counts_expr(0.4),
        14,
        counts_expr(0.4),
        16,
        counts_expr(0.8),
        19,
        counts_expr(1),
      ];
    } else {
      return icon_size_expression;
    }
  }

  const segmentLayerDefinition = {
    id: kSegmentLayerId,
    type: 'line',
    source: kSegmentSourceId,
    'source-layer': kSegmentLayerSourceLayerId,
    layout: {},
    paint: {
      'line-color': getColorExpression(timelineMetric),
      'line-width': getLineWidthExpression(timelineMetric),
      'line-opacity': 0.7,
      'line-offset': getLineOffsetExpression(),
    },
  };

  const segmentArrowLayerDefinition = {
    ...kSegmentLayerArrowDefinition,
    paint: {
      'icon-color': getColorExpression(timelineMetric),
      'icon-opacity': kMainVisLineOpacityExpression(kMainVisLineOpacity),
    },
  };

  segmentArrowLayerDefinition.layout['icon-image'] =
    userProject?.right_hand_drive
      ? kHalfDirectionArrowNameRHD
      : kHalfDirectionArrowNameLHD;
  segmentArrowLayerDefinition.layout['icon-size'] = getIconSizeExpression(
    timelineMetric,
  ) as any;
  (segmentArrowLayerDefinition.layout as any).visibility =
    ToggleLayerControl.getValue(kTicksShowHideId) ? 'visible' : 'none';

  const hoverLayerDefinition = {
    ...segmentLayerDefinition,
    id: kSegmentHoverId,
    paint: {
      ...segmentLayerDefinition.paint,
      'line-color': '#000000',
      'line-opacity': 0,
    },
  };

  function updateColorExpression() {
    lastColorUpdate = Date.now();
    refMap.current.setFilter(
      kSegmentLayerId,
      getShowExpression(timelineMetric),
    );
    refMap.current.setPaintProperty(
      kSegmentLayerId,
      'line-width',
      getLineWidthExpression(timelineMetric),
    );
    refMap.current.setPaintProperty(
      kSegmentLayerId,
      'line-color',
      getColorExpression(timelineMetric),
    );
    if (ToggleLayerControl.getValue(kTicksShowHideId)) {
      refMap.current.setPaintProperty(
        kSegmentSymbolsLayerId,
        'icon-color',
        getColorExpression(timelineMetric),
      );
      const ice = getIconSizeExpression(timelineMetric);
      refMap.current.setLayoutProperty(
        kSegmentSymbolsLayerId,
        'icon-size',
        getIconSizeExpression(timelineMetric),
      );
    }
    refExpectingSDE.current = Date.now();
  }

  const handleSegmentClickCallback = useCallbackWithErrorHandling((event) => {
    // console.log(`handleSegmentClickCallback ${JSON.stringify(event.lngLat)} refSegments.current ${JSON.stringify(refSegments.current.length)}`);
    if (refInLocationEditMode.current) {
      dispatch(setDisplayedBookmark(launderLatLng(event.lngLat)));
    } else {
      if (mapShowingSelectedSegment(refMap.current))
        mapPopSelectedSegment(refMap.current);
      dispatch(setRouteSelection(undefined));
      dispatch(setSelectedSegmentId(undefined));
    }
  });

  function showMouseTooltipForFeature(feature, incident) {
    refMap.current.getCanvas().style.cursor = 'pointer';
    if (refMouseoverTimeout.current) {
      clearTimeout(refMouseoverTimeout.current);
    }
    const innerHtml = formatInfoToHtml(
      feature,
      incident,
      refProjectUsesMetric.current,
      timeOfDayOffsetRef.current.time,
      timeOfDayOffsetRef.current.offset,
      refTimelineMetric.current,
    );
    // Populate the popup and set its coordinates (otherwise it doesn't show)
    refPopup.current.setLngLat([0, 0]).setHTML(innerHtml).addTo(refMap.current);
  }

  function hideMouseover() {
    refPopup.current.remove();
    refMouseoverTimeout.current = undefined;
  }

  const handleMouseLeaveEvent = useCallbackWithErrorHandling((event) => {
    if (!refMouseoverTimeout.current) {
      if (refPopup.current.isOpen()) {
        refMouseoverTimeout.current = {
          handle: setTimeout(hideMouseover, kMouseHoverTimeoutMillis),
          set: Date.now(),
        };
      }
    } else if (
      Date.now() - refMouseoverTimeout.current.set >
      kMouseHoverTimeoutMillis * 2
    ) {
      hideMouseover();
    }
  });

  const handleMouseMoveEvent = useCallbackWithErrorHandling(
    (event: mapboxgl.MapMouseEvent) => {
      const { point } = event;
      if (!mapShowingSegments(refMap.current)) return;
      const nearestSegment = getNearestSegmentFromMouseEvent(
        refMap.current,
        event,
        kSegmentHoverId,
      );
      const incident = findNearestIncidentInBox(refMap.current, point);

      if (nearestSegment || incident) {
        showMouseTooltipForFeature(nearestSegment, incident);
      } else {
        handleMouseLeaveEvent(event);
      }
    },
  );

  const handleMapMoveCallback = useCallbackWithErrorHandling(() => {
    const center = {
      lng: refMap.current.getCenter().lng.toFixed(4),
      lat: refMap.current.getCenter().lat.toFixed(4),
    };
    const newZoom = refMap.current.getZoom().toFixed(2);
    dispatch(setMapZoom(newZoom));
    dispatch(setMapCenter(center));
  });

  function handleTicksShowHideClickCallback() {
    toggleLayerVisibility(
      kTicksShowHideId,
      kSegmentSymbolsLayerId,
      refMap.current,
    );
  }

  function handleIncidentsShowHideClickCallback() {
    if (isRealtimeMetric(refTimelineMetric.current)) {
      const newShow = !refShowIncidents.current;
      dispatch(setShowIncidents(newShow));
      ToggleLayerControl.setValue(newShow, kIncidentsShowHideId);
    }
  }

  function handleZoomPromptUpdate() {
    if (refMap.current) {
      if (refMap.current.getZoom() < timelineMinZoom) {
        setZoomPrompt(true);
      } else {
        setZoomPrompt(false);
      }
    }
  }

  function updateFeaturesWithRt() {
    if (refMap.current && mapHasLayer(refMap.current, kSegmentLayerId)) {
      if (refRealtimeData.current) {
        // console.debug('Loading rt data into the map');
        const speed_key = 'rtspeed';
        const rt_keys = Object.keys(refRealtimeData.current.data);
        for (let i = 0; i < rt_keys.length; i++) {
          const id = rt_keys[i];
          refMap.current.setFeatureState(
            {
              source: kSegmentSourceId,
              sourceLayer: kSegmentLayerSourceLayerId,
              id,
            },
            { [speed_key]: refRealtimeData.current.data[id] },
          );
        }
        return true;
      }
    }
    return false;
  }

  function updateFeaturesWithVNRT() {
    if (refMap.current && mapHasLayer(refMap.current, kSegmentLayerId)) {
      const l_vnrtData = refVnrtData.current;
      if (Object.keys(l_vnrtData).length > 0) {
        // console.debug('Loading vnrt data into the map');
        const segs = {};
        const slots_keys = Object.keys(l_vnrtData);
        for (let i = 0; i < slots_keys.length; i++) {
          for (let j = 0; j < l_vnrtData[slots_keys[i]].length; j++) {
            const r = l_vnrtData[slots_keys[i]][j];
            if (!segs[r.s]) segs[r.s] = [];
            segs[r.s].push(r);
          }
        }
        // Segs contains a map of time and value objects
        const segs_keys = Object.keys(segs);
        for (let i = 0; i < segs_keys.length; i++) {
          const id = segs_keys[i];
          const new_state = {};
          for (let j = 0; j < segs[id].length; j++) {
            const data = segs[id][j];
            const speed_offset_key = `s${data.o.toFixed(0)}`;
            new_state[speed_offset_key] = data.v;
          }
          refMap.current.setFeatureState(
            {
              source: kSegmentSourceId,
              sourceLayer: kSegmentLayerSourceLayerId,
              id,
            },
            new_state,
          ); // Need to convert time -> offset by dividing by length of bin, then format the speed key with it
        }
        refExpectingSDE.current = Date.now();
      }
      return true;
    }
    return false;
  }

  function updateSlotsRepresented() {
    refUpdateSlotsRepresentedTimeout.current = null;
    if (refMap.current && mapHasLayer(refMap.current, kSegmentLayerId)) {
      // console.debug('Update slots represented');
      const renderedFeatures = refMap.current.querySourceFeatures(
        kSegmentSourceId,
        {
          sourceLayer: kSegmentLayerSourceLayerId,
        },
      );
      const slots = {};
      let relevant_features = 0;
      for (let i = 0; i < renderedFeatures.length; i++) {
        // Probably can magically subselect only a handful of features? Might only ever get to around 1000 so not worth it
        const feature = renderedFeatures[i];
        if (feature.properties.date === refTargetDate.current) {
          relevant_features += 1;
          const props = feature.properties || {};
          const state = feature.state || {};
          const keys = [...Object.keys(props), ...Object.keys(state)];
          for (let j = 0; j < keys.length; j++) {
            const k = keys[j];
            if (k.startsWith('s') && (props[k] || state[k])) {
              slots[k] = (slots[k] || 0) + 1;
            }
          }
        }
      }
      const final_slots = {};
      const slots_keys = Object.keys(slots);
      for (let i = 0; i < slots_keys.length; i++) {
        const k = slots_keys[i];
        if (slots[k] > relevant_features / 2) {
          // Slot is represented if more than 1/2 of the features have it
          final_slots[k.replace('s', '')] = true;
        }
      }
      dispatch(setRepresentedSlots(final_slots));
      return true;
    }
    return false;
  }

  const callUpdateSlotsRepresentedDelayed = () => {
    if (refUpdateSlotsRepresentedTimeout.current) {
      clearTimeout(refUpdateSlotsRepresentedTimeout.current);
      // console.log('Cleared timeout');
    }
    refUpdateSlotsRepresentedTimeout.current = setTimeout(
      updateSlotsRepresented,
      kUpdateSlotsRepresentedTimeoutMs,
    );
  };

  const handleSourceDataEvent = useCallbackWithErrorHandling(
    (e: mapboxgl.MapSourceDataEvent) => {
      // Given we now rely on the segments being loaded via tiles to render
      // Selected and routes, we have to listen to new tiles being rendered
      // In case the selected segments or any of the route segments are suddenly in view
      if (e.sourceId === kSegmentSourceId) {
        const tileId = e.tile?.tileID?.canonical;
        if (tileId) {
          const textTileId = `${refTargetDate.current},${tileId.z},${tileId.x},${tileId.y}`;
          if (
            !refEnrichedTiles.current[textTileId] &&
            !refDayInfo.current?.is_nrt
          ) {
            // if (refExpectingSDE.current) console.log(refExpectingSDE.current);
            refExpectingSDE.current = undefined;
            const vnrt_enriched = updateFeaturesWithVNRT();
            const rt_enriched =
              updateFeaturesWithRt() || refDayInfo.current?.is_complete_vnrt;
            callUpdateSlotsRepresentedDelayed();
            const enriched = rt_enriched && vnrt_enriched;
            if (enriched) {
              refEnrichedTiles.current[textTileId] = true;
              // console.log('Marking tile enriched', textTileId);
            } else {
              // console.log('Tile not sufficiently enriched', textTileId, vnrt_enriched, rt_enriched, slots_enriched);
            }
          } else {
            // console.log('Tile already enriched', textTileId);
          }
        }
      }
    },
  );

  const handleMapLoadCallback = useCallbackWithErrorHandling(() => {
    // console.log('Map ready!');
    setMapReady(true);
    refStyleReady.current = true;
    updateFeaturesWithRt();
    updateFeaturesWithVNRT();
    updateSlotsRepresented();
  });

  const handleMapSettingsClickCallback = (e) => {
    setSettingsOpen(true);
  };

  useEffect(() => {
    // Wipe old map
    if (refMap.current) {
      refMap.current.remove();
      refMap.current = undefined;
      setMapReady(false);
    }

    const { center, zoom } = getInitialCenter(userProject);
    if (!center) {
      // Project defaults not yet loaded, skipping initialisation of map
      return;
    }

    // Init new map
    refStyleReady.current = false;
    setMapReady(false);
    initMap(
      refMap,
      refMapContainer,
      center,
      zoom,
      mapStyle,
      userInfo.has_hidden_features,
      handleMapLoadCallback,
      handleMapMoveCallback,
      handleSegmentClickCallback,
      handleMapSettingsClickCallback,
      dispatch,
      userProject,
    );
    lastColorUpdate = Date.now();
    refPopup.current = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: alwaysUseHamburgerNav ? 'hamburger-mapboxgl-popup' : '',
    });
    refMap.current.on('mousemove', handleMouseMoveEvent);
    refMap.current.on('sourcedata', handleSourceDataEvent);
    refMap.current.addControl(
      new ToggleLayerControl(
        kTicksShowHideId,
        'Show/hide ticks',
        false,
        handleTicksShowHideClickCallback,
      ),
    );
    if (projectHasIncidents) {
      refMap.current.addControl(
        new ToggleLayerControl(
          kIncidentsShowHideId,
          isRealtimeMetric(timelineMetric)
            ? kIncidentsShowHideNormalMessage
            : kIncidentsShowHideDisabledMessage,
          isRealtimeMetric(timelineMetric)
            ? showIncidents
            : ToggleLayerControlValue.disabled,
          handleIncidentsShowHideClickCallback,
        ),
      );
    }
    refMap.current.on('zoomend', handleZoomPromptUpdate);
    (refMap.current as any).cemStyle = mapStyle;
    handleZoomPromptUpdate();
  }, [userProject, mapStyle]); // eslint-disable-line react-hooks/exhaustive-deps

  // redrawSegments
  useEffect(
    () => {
      // console.log(`============= useeffect redrawSegments (mapReady: ${mapReady}`);
      // console.log('Map ready: ', mapReady);
      if (
        mapReady &&
        refMap.current &&
        refStyleReady.current &&
        timelineMetric &&
        comparison
      ) {
        (segmentArrowLayerDefinition.layout as any).visibility =
          ToggleLayerControl.getValue(kTicksShowHideId) ? 'visible' : 'none';
        redrawBaseSegments(
          mapReady,
          refMap.current,
          segmentLayerDefinition,
          `server/tiles/timeline/${project}/${targetDate}/${comparison}`,
          segmentArrowLayerDefinition,
          hoverLayerDefinition,
          timelineMinZoom,
          16,
        );
        if (projectHasIncidents) {
          redrawIncidents(
            refMap.current,
            userProject.project_slug,
            shouldShowIncidents(showIncidents, timelineMetric),
          );
        }
      }
    },
    // we don't need dependency on selectedSegmentId even though we draw the selected statement
    // because (besides initial render) it only erasing/drawing it is handled by handleSegmentClickCallback
    [
      mapReady,
      targetDate,
      comparison,
      selectedSegmentId,
      selectedRoute,
      timelineMetric,
    ], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (refMap.current) {
      const { center } = getInitialCenter(userProject);
      refMap.current.jumpTo({ center });
    }
  }, [userProject]);

  useEffect(() => {
    if (mapShowingSegments(refMap.current)) {
      if (
        !lastColorUpdate ||
        Date.now() - lastColorUpdate > kColorUpdateMillis
      ) {
        clearTimeout(lastColorUpdateRef);
        updateColorExpression();
      } else {
        clearTimeout(lastColorUpdateRef);
        lastColorUpdateRef = setTimeout(
          updateColorExpression,
          kColorUpdateMillis,
        );
      }
    }
  }, [timeOfDayOffset, timelineMetric, targetDate]);

  useEffect(() => {
    updateMapLocation(refMap.current, mapZoom, currentMapCenter);
  }, [currentMapCenter, mapZoom]);

  useEffect(() => {
    if (refTargetDate.current !== targetDate) {
      console.log('Changed dates, wiping represented slots and enriched tiles');
      dispatch(setRepresentedSlots({}));
      refEnrichedTiles.current = {};
    }
    refTargetDate.current = targetDate;
  }, [targetDate]);

  useEffect(() => {
    refRealtimeData.current = realtimeData;
    updateFeaturesWithRt();
  }, [realtimeData, timelineMetric]);

  useEffect(() => {
    updateFeaturesWithVNRT();
  }, [timelineMetric, refVnrtData.current]);

  useEffect(() => {
    refTimelineMetric.current = timelineMetric;
  }, [timelineMetric]);

  useEffect(() => {
    refVnrtData.current = vnrtData;
  }, [vnrtData]);

  useEffect(() => {
    refDayInfo.current = day_info;
  }, [day_info]);

  useEffect(() => {
    refProjectUsesMetric.current = userProject?.uses_metric;
  }, [userProject]);

  useEffect(() => {
    refShowIncidents.current = showIncidents;
    if (mapReady) {
      setIncidentLayerVisibility(refMap.current, showIncidents, timelineMetric);
      if (!isRealtimeMetric(timelineMetric)) {
        ToggleLayerControl.setValue(
          ToggleLayerControlValue.disabled,
          kIncidentsShowHideId,
        );
        ToggleLayerControl.setTitle(
          kIncidentsShowHideDisabledMessage,
          kIncidentsShowHideId,
        );
      } else {
        ToggleLayerControl.setValue(showIncidents, kIncidentsShowHideId);
        ToggleLayerControl.setTitle(
          kIncidentsShowHideNormalMessage,
          kIncidentsShowHideId,
        );
      }
    }
  }, [timelineMetric, showIncidents, mapReady]);

  useEffect(() => {
    refInLocationEditMode.current = inLocationEditMode;
    if (mapReady) {
      if (inLocationEditMode) {
        if (!displayedBookmark) {
          dispatch(setDisplayedBookmark({ ...refMap.current.getCenter() }));
        } else {
          const bookmarks = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: latLngToJsonPoint(displayedBookmark),
              },
            ],
          };
          showBookmarks(refMap.current, bookmarks);
        }
      } else {
        safeRemoveBookmarks(refMap.current);
      }
    }
  }, [mapReady, dispatch, inLocationEditMode, displayedBookmark]);

  return (
    <div ref={refMapContainer} className={styles.map}>
      <MapLoadingSpinner currentRefMap={refMap} mapReady={mapReady} />
      <TimelineLegend
        metric={timelineMetric}
        hourlyMaxCount={hourlyMaxCount.current}
      />
      {zoomPrompt && <FloatingDiv>Zoom in to view timeline</FloatingDiv>}
      <OutsideBboxPrompt currentRefMap={refMap} />
      <MapSettings
        open={settingsOpen}
        handleVisSwitch={(visible) => setSettingsOpen(visible)}
      />
    </div>
  );
}
