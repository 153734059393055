import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  selectComparison,
  selectProject,
  selectTargetDate,
} from '../../state/workflowSlice';
import {
  useGetTypicalRouteTravelTimeQuery,
  useGetRouteTravelTimeQuery,
} from '../../state/apiSlice';
import { isComparisonMonth } from '../task_bar/ComparisonPicker';
import { useRouteInfo } from '../common/useRouteInfo';

export function useTravelTimeDataProvider() {
  const project_slug = useSelector(selectProject);
  const targetDate: string = useSelector(selectTargetDate);
  const comparison = useSelector(selectComparison);

  const { basicRouteInfo } = useRouteInfo();

  const isMonthComparison = comparison && isComparisonMonth(comparison);

  const routeSegments = basicRouteInfo.realizedSegments;

  const hasSegments = routeSegments && routeSegments.length > 0;

  const { currentData: ttData } = useGetRouteTravelTimeQuery(
    { project_slug, date: targetDate, routeIds: routeSegments },
    { skip: !(targetDate && hasSegments) },
  ) as any;

  const { currentData: typicalTtData } = useGetTypicalRouteTravelTimeQuery(
    {
      project_slug,
      typicalMonth: comparison,
      routeIds: routeSegments,
      date: targetDate,
    },
    { skip: !(comparison && hasSegments) || !isMonthComparison },
  ) as any;

  const { currentData: comparisonNrtData } = useGetRouteTravelTimeQuery(
    { project_slug, date: comparison, routeIds: routeSegments },
    { skip: !(comparison && hasSegments) || isMonthComparison },
  ) as any;

  const comparisonData = typicalTtData || comparisonNrtData;

  return {
    ttData,
    comparisonData,
  };
}
