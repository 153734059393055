import React from 'react';

import { useSelector } from 'react-redux';

import {
  selectRouteSelection,
  selectSegmentId,
} from '../../state/workflowSlice';
import { BaseNoSegmentSelectedPanel } from '../workspace/NoSegmentSelectedPanel';
import noRouteSelected from './Route_selection_instructions.png';
import instructions_desktop from './instructions_desktop.png';
import instructions_mobile from './instructions_mobile.png';

export function NoRouteSelectedPanel() {
  const segmentId = useSelector(selectSegmentId);
  const routeSelection = useSelector(selectRouteSelection);
  if (segmentId === undefined && routeSelection === undefined) {
    return (
      <BaseNoSegmentSelectedPanel>
        <style>
          {`
          .no_route_selected {
            max-height: 70%;
            max-width: 60vw;
            min-width: 300px;
            height: 100%;
            width: 100%;
            background-image: url(${instructions_desktop});
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            }
          @media (pointer:coarse) {
            .no_route_selected {
               background-image: url(${instructions_mobile}) !important;
             }
           }`}
        </style>
        <div
          className="no_route_selected"
          title="To select a route, first select a single segment as usual then hold ctrl for future selections"
        />
      </BaseNoSegmentSelectedPanel>
    );
  } else {
    return undefined;
  }
}
