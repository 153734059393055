import {
  stl_background_color,
  stl_blue_color,
  stl_gold_color,
} from 'theme/cemTheme';
import {
  kMainVisLineOpacity,
  mapHasLayer,
  mapLayerSafeRemove,
  mapSourceSafeRemove,
  thick_line_width_expression,
} from 'features/map/mapCommon';
import {
  bboxFromPointBuffer,
  setLayerVisibility,
  visibleProperty,
} from 'features/map/mapUtils';
import mapboxgl from 'mapbox-gl';
import { isRealtimeMetric } from 'features/workflow_timeline/metricsOptions';
import { timelineMinZoom } from 'features/workflow_timeline/TimelineMap';
import { kDefaultIconImg } from 'features/map/MapIconProvider';

export const kIncidentPoILayerId = 'incidents_poi'; // Little icons
export const kIncidentFlowLayerId = 'incidents_flow'; // closure snakes
export const kIncidentSourceId = 'incidents';

export const kIncidentsShowHideNormalMessage =
  'Show/Hide Realtime Incidents layer';
export const kIncidentsShowHideDisabledMessage =
  'Incidents layer only available for realtime';

const situation_magnitude_coloring = [
  'match',
  ['get', 'magnitude'],
  0,
  stl_blue_color,
  1,
  stl_gold_color,
  2,
  '#daad3c',
  3,
  '#d2791f',
  4,
  '#E00000',
  stl_blue_color,
];

export function shouldShowIncidents(newShow, metric) {
  return newShow && isRealtimeMetric(metric);
}

export function setIncidentLayerVisibility(map, newShow, metric) {
  setLayerVisibility(
    shouldShowIncidents(newShow, metric),
    kIncidentPoILayerId,
    map,
  );
  setLayerVisibility(
    shouldShowIncidents(newShow, metric),
    kIncidentFlowLayerId,
    map,
  );
}

export function redrawIncidents(
  map: mapboxgl.Map,
  project_slug: string,
  startVisible: boolean,
) {
  mapLayerSafeRemove(map, kIncidentPoILayerId);
  mapLayerSafeRemove(map, kIncidentFlowLayerId);
  mapSourceSafeRemove(map, kIncidentSourceId);

  const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/server/tiles/situation_records/${project_slug}/{z}/{x}/{y}/`;

  map.addSource(kIncidentSourceId, {
    type: 'vector',
    tiles: [url],
  });

  const flowLayer = {
    id: kIncidentFlowLayerId,
    type: 'line',
    source: kIncidentSourceId,
    'source-layer': 'situation_records_flow',
    minzoom: timelineMinZoom,
    paint: {
      'line-color': situation_magnitude_coloring,
      'line-width': thick_line_width_expression,
      'line-dasharray': [0, 1, 1],
      'line-opacity': kMainVisLineOpacity,
    },
    layout: {
      visibility: visibleProperty(startVisible),
    },
  } as any;
  map.addLayer(flowLayer);

  const poiLayer = {
    id: kIncidentPoILayerId,
    type: 'symbol',
    source: kIncidentSourceId,
    'source-layer': 'situation_records_poi',
    metadata: 'group:incidents_marker',
    minzoom: timelineMinZoom,
    paint: {
      'icon-color': situation_magnitude_coloring,
      'icon-halo-color': stl_background_color,
      'icon-halo-width': 4,
    },
    layout: {
      visibility: visibleProperty(startVisible),
      'icon-allow-overlap': false,
      'icon-anchor': 'center',
      'icon-ignore-placement': true,
      'icon-image': ['get', 'primary_alert_c_event_category'],
      'symbol-avoid-edges': true,
      'symbol-placement': 'point',
    },
  } as any;
  map.addLayer(poiLayer);
}

export function findNearestIncidentInBox(map: mapboxgl.Map, point) {
  const bbox = bboxFromPointBuffer(point, 8);
  const features = [];
  if (mapHasLayer(map, kIncidentPoILayerId)) {
    features.push(
      ...map.queryRenderedFeatures(bbox, {
        layers: [kIncidentPoILayerId],
      }),
    );
  }
  // We split it up because mapboxgl has much worse performance for some reason when you have multiple layers
  if (mapHasLayer(map, kIncidentFlowLayerId)) {
    features.push(
      ...map.queryRenderedFeatures(bbox, { layers: [kIncidentFlowLayerId] }),
    );
  }
  if (features.length > 0) {
    return features[0]; // TODO get closest
  }
  return undefined;
}
