import React, { useContext } from 'react';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
  selectSegmentId,
  selectCurrentProjectInfo,
  selectComparison,
  selectTargetDate,
} from 'state/workflowSlice';
import { useGetSegmentInfoQuery } from 'state/apiSlice';

import { nrtCommonInfo, triggerCsvExport } from 'features/common/exportUtils';
import { formatDistance } from 'features/common/utils';
import {
  BIN_WIDTH_MINUTES_TO_MS,
  getFormattedTime,
} from 'features/chart/nrtChartCommon';

import { ExportButton } from 'features/task_bar/ExportButton';
import styles from 'features/task_bar/Taskbar.module.css';
import { LayerContext } from 'state/LayerContext';
import { isOnDay, parseDate } from 'appUtils';
import { useTravelTimeDataProvider } from 'features/workflow_traveltime/nrtTravelTimeCommon';
import { TravelTimeChartDataExpander } from 'features/chart/TravelTimeChartDataExpander';
import { useRouteInfo } from 'features/common/useRouteInfo';

export function ExportTravelTimesButton() {
  const comparison = useSelector(selectComparison);
  const targetDate = useSelector(selectTargetDate);
  const segmentId = useSelector(selectSegmentId);
  const userProject = useSelector(selectCurrentProjectInfo);

  // cached so no need to memoize
  const { layer } = useContext(LayerContext);
  const { currentData: segmentData } = useGetSegmentInfoQuery(
    { layerId: layer, routeIds: [segmentId] },
    { skip: !layer || !segmentId },
  );

  const { feature: routeFeature, savedRoute } = useRouteInfo();

  const segment = segmentData && segmentData?.segments[segmentId];

  // typicalData from REST api
  const { ttData, comparisonData } = useTravelTimeDataProvider();

  const handleClick = (event) => {
    const dataExpander = new TravelTimeChartDataExpander(targetDate);
    const comparisonExpander = new TravelTimeChartDataExpander(
      targetDate,
      comparison,
    );
    const data = dataExpander.expandTravelTimes(ttData);
    const expandedComparisonData =
      comparisonExpander.expandTravelTimes(comparisonData);

    const format = (v) => {
      if (v || v === 0) {
        const d = dayjs.duration(v, 's');
        return d.format('HH:mm:ss');
      } else {
        return '';
      }
    };

    const csv = [
      `Name, ${savedRoute ? savedRoute.routeName : segment.properties.name}`,
      `ID, ${segmentId}`,
      ...nrtCommonInfo(
        targetDate,
        comparison,
        segment,
        userProject.uses_middle_east_day_types,
      ),
      `Route Length, ${formatDistance(
        routeFeature.properties.route_length,
        userProject.uses_metric,
      )}`,
      comparisonData.freeflow_traveltime
        ? `Freeflow traveltime, ${format(comparisonData.freeflow_traveltime)}`
        : null,
      '',
      '',
      `Time Start, Time End, ${targetDate} Traveltime (HH:MM:SS), ${comparison} Traveltime (HH:MM:SS)`,
    ];

    for (let i = 0; i < data.length; i += 1) {
      const row = data[i];
      const compRow = expandedComparisonData[i];
      const time = row.x;
      const endtime = time + ttData.bin_size * BIN_WIDTH_MINUTES_TO_MS;
      if (isOnDay(time, targetDate)) {
        csv.push(
          `${getFormattedTime(time)},${getFormattedTime(endtime)},${format(
            row.y,
          )},${format(compRow.y)}`,
        );
      }
    }
    // console.log(`ExportVolumeButton segmentVolumes ${JSON.stringify(data)}`);
    const formattedDate = parseDate(targetDate).format('MMDDYYYY');
    triggerCsvExport(
      `traveltimes_${formattedDate}_${
        savedRoute
          ? savedRoute.routeName.replace(' ', '_').toLowerCase()
          : segmentId
      }.csv`,
      csv,
    );
  };

  const isActive = !!ttData && !!comparisonData && !!routeFeature;

  return (
    <div className={styles.task_bar_component_bottom}>
      <ExportButton isActive={isActive} handleClick={handleClick} />
    </div>
  );
}
