import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {
  setWorkflow,
  selectWorkflow,
  selectCurrentProjectInfo,
} from 'state/workflowSlice';

import {
  WORKFLOW_PLAN_NAMES,
  WORKFLOWS_ALL_ENABLED,
  WorkflowType,
} from 'appConstants';

import styles from './NavBar.module.css';
import { selectUserState } from '../../state/userSlice';

export function NavBar() {
  const workflow = useSelector(selectWorkflow);
  const userProject = useSelector(selectCurrentProjectInfo);
  const userInfo = useSelector(selectUserState);
  const dispatch = useDispatch();
  const disableDisabledFeatures = !userInfo.has_hidden_features;
  const [menuOpen, setMenuOpen] = useState(true);

  let enabledWorkflows = userProject?.features
    ? WORKFLOWS_ALL_ENABLED.filter((value) =>
        userProject.features.includes(value),
      )
    : [];

  if (!disableDisabledFeatures) {
    enabledWorkflows = WORKFLOWS_ALL_ENABLED;
  }

  enabledWorkflows = enabledWorkflows.sort((a, b) => {
    const a_enabled = userProject?.features.includes(a);
    const b_enabled = userProject?.features.includes(b);
    if ((a_enabled && b_enabled) || (!a_enabled && !b_enabled)) {
      return 0;
    } else if (a_enabled && !b_enabled) {
      return -1;
    } else {
      return 1;
    }
  });

  useEffect(() => {
    if (!enabledWorkflows.includes(workflow)) {
      dispatch(setWorkflow(enabledWorkflows[0]));
    }
  }, [workflow, userProject]);

  const menu = (
    <List disablePadding>
      {enabledWorkflows.map((workflow_name, index) => {
        const isIncludedWorkflow =
          userProject?.features.includes(workflow_name);
        const unchosenClass = isIncludedWorkflow
          ? styles.nav_button
          : styles.nav_button_disabled;
        return (
          <ListItem
            key={workflow_name}
            disablePadding
            className={
              workflow === workflow_name
                ? styles.nav_button_chosen
                : unchosenClass
            }
          >
            <ListItemButton
              selected={workflow === workflow_name}
              onClick={(e) => {
                e.currentTarget.blur();
                dispatch(setWorkflow(workflow_name));
                setMenuOpen(false);
              }}
              className={styles.list_item_button}
            >
              <ListItemText primary={WORKFLOW_PLAN_NAMES[workflow_name]} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );

  const getMaybeHamburgerStyles = () => {
    const alwaysUseHamburgerNav = false;
    if (alwaysUseHamburgerNav) {
      return {
        nav_perm_style: styles.hamburger_nav_bar_permanent,
        click_style: styles.hamburger_mobile_nav_click_catcher,
        menu_style: styles.hamburger_mobile_nav_container_menu,
        button_style: styles.hamburger_mobile_nav_container_button,
      };
    }

    return {
      nav_perm_style: styles.nav_bar_permanent,
      click_style: styles.mobile_nav_click_catcher,
      menu_style: styles.mobile_nav_container_menu,
      button_style: styles.mobile_nav_container_button,
    };
  };

  const maybeHamburgerStyles = getMaybeHamburgerStyles();

  return (
    <>
      <div className={maybeHamburgerStyles.nav_perm_style}>{menu}</div>
      {menuOpen && (
        <div // eslint-disable-line jsx-a11y/no-static-element-interactions
          className={maybeHamburgerStyles.click_style}
          onClick={(ev) => {
            setMenuOpen(false);
          }}
          onKeyUp={(ev) => {
            if (ev.key === 'Escape') {
              setMenuOpen(false);
            }
          }}
        />
      )}
      <div
        className={
          menuOpen
            ? maybeHamburgerStyles.menu_style
            : maybeHamburgerStyles.button_style
        }
      >
        {!menuOpen && (
          <IconButton type="button" onClick={() => setMenuOpen(true)}>
            <MenuIcon />
          </IconButton>
        )}
        {menuOpen && (
          <>
            <div className={styles.close_nav_bar_button}>
              <IconButton onClick={() => setMenuOpen(false)}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            {menu}
          </>
        )}
      </div>
    </>
  );
}
