import React from 'react';

import { ReactComponent as TrafficLight } from './product_icons/traffic_light.svg';
import { ReactComponent as TrafficCone } from './product_icons/traffic_cone.svg';
import { ReactComponent as RoadBarrier } from './product_icons/road_barrier.svg';
import { ReactComponent as Road } from './product_icons/road.svg';

export enum ProductType {
  insight_full = 'insight_full',
  safety_prioritize = 'safety_prioritize',
  traffic_monitor = 'traffic_monitor',
  construction = 'construction',
}

export interface ProductInfo {
  product_type: ProductType;
  product_name: string;
  product_url: string;
  subscription_expired: boolean;
  org_id: string;
}

export const PRODUCT_DETAILS = {
  [ProductType.insight_full]: {
    image: <TrafficLight />,
  },
  [ProductType.safety_prioritize]: {
    image: <TrafficCone />,
  },
  [ProductType.traffic_monitor]: {
    image: <RoadBarrier />,
  },
  [ProductType.construction]: {
    image: <Road />,
  },
};
